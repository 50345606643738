import React, { useState } from 'react'
import Nav from '../Nav/Nav';
import ContactForm from '../Extras/ContactForm/ContactForm';
import styles from './getInTouch.module.css';
import Faq from "../Extras/Faqs/Faqs"
import Footer from "../Extras/Footer/Footer"
const GetInTouch = () => {
     const [active, setActive] = useState(1);
     const [search, setsearchText]= useState("")
     
  const isMobile = window.screen.width < 698;
    return (
        <div>
             <Nav hideTabs={true}/>

    <div className={styles.title_button_block}>
        <div className={styles.heading1}>

        <h2 className={styles.heading1_h}> Get in < span class={styles.bold}>Touch</span></h2>
        <p className={styles.heading1_p}>Bditto is a powerful enterprise solution that will help you easily share, connect and explore thoughts in an expedited manner.
        </p>
    </div> 
    <div className={styles.contact}>
        <ContactForm leftHeading={false} />
    </div>
    <div className={styles.heading1} style={{marginTop: isMobile ? '10vh' : '35vh'}}>
    <h2 className={styles.heading1_h} style={{width:  isMobile ? '100%':'90%',textAlign:'center'}}> How can we < span class={styles.bold}>help you&nbsp;</span>?</h2>
        <div className={styles.searchContainer}>
        <img src="/icons/search.svg" className={styles.searchIcon}/>
        <input placeholder="Ask a question..." value={search} onChange={e =>setsearchText(e.target.value)} className={styles.seachBox} />
        <button className={styles.searchBtn}>Search</button>
        </div>
    </div> 
      <Faq searchText={search}/>
      </div>    
        </div>
    )
}

export default GetInTouch
