import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./Explore.module.css";
import { FormattedMessage } from "react-intl";
import { IconButton , Avatar} from "@material-ui/core";
import { Link } from "react-router-dom";
import StatusCard from "../../Extras/StatusCard/StatusCard";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import ExploreStatusCard from "../../Extras/StatusCard/ExploreCards";

import { useMediaQuery } from "react-responsive";

const Explore =(props)=> {
 
const isSmallTab =  useMediaQuery({maxWidth: 1058 });
    const Feature_Card_Data = [
      {
          border: 'red',
          avatar: '/dashboard/user1.svg',
          content: "Do we have to wear masks in the office "
      },
      {
          border: 'blue',
          avatar:"/dashboard/user2.svg",
          content: "Feel free to join our company run this Friday"
      },
      {
          border: 'green',
          avatar:"/dashboard/user3.svg",
          content: "Where to find the new marketing 21 Kick off presentation"
      },
      {
          border: 'yellow',
          avatar:"/dashboard/user4.svg",
          content: "Printer problems, help needed"
      },
    ];

    const Popular_Card_Data = [
      {
          border: 'red',
          avatar: '/dashboard/user1.svg',
          content: "Are our vacation policies changing due to Covid-19"
      },
      {
          border: 'blue',
          avatar:"/dashboard/user2.svg",
          content: "Please join tomorrows townhall meeting, some great news coming"
      },
      {
          border: 'green',
          avatar:"/dashboard/user3.svg",
          content: "Are dogs allowed in our office"
      },
      {
          border: 'yellow',
          avatar:"/dashboard/user4.svg",
          content: "How to open an IT ticket"
      },
    ];

    const Buzzing_Card_Data = [
      {
          border: 'red',
          avatar: '/dashboard/user1.svg',
          content: "We made a new multi billion dollar deal with new customer. Excited to share the news"
      },
      {
          border: 'blue',
          avatar:"/dashboard/user2.svg",
          content: "Covid 19 vaccine drive is coming to our company"
      },
      {
          border: 'green',
          avatar:"/dashboard/user3.svg",
          content: "Opening office after the lockdown restrictions are lifted"
      },
      {
          border: 'yellow',
          avatar:"/dashboard/user4.svg",
          content: "Stay tuned for more updates about Christmas event this year."
      },
    ];

    const [slideIndex, setSlideIndex] = React.useState(0)

    const settings = {
        dots: false,
        infinite: false,
        speed: 1000,
        slidesToShow: isSmallTab? 1.8 : 2.9,
        centerMode: false,
        slidesToScroll: isSmallTab? 1.8 : 2.8,
        centerPadding: 50,
        centerMode: false,
        beforeChange: (current, next) => setSlideIndex(next)
    };

    return (
      <div className={styles.mainContainer} id="Suggestions">
        <div className={styles.grpContainer}>
          <Avatar style={{width:'1.7em' , height:'1.7em', background: "#D9DEF4", color: "#333"}}>G</Avatar>
          <span className={styles.grpname}>Global</span>
          <span >
            <img src="/dashboard/chevronDown.svg" style={{width:'1.05em' , height:'1.05em',marginTop:'0.4rem',}} />
          </span> 
        </div>
        <div className={styles.exploreDisply}>
          <div className={styles.bredcrumb}>
            <h3 className={styles.selectBread} >
              <img src="/dashboard/timeBig.svg" style={{marginBottom:'-0.3rem'}} />&nbsp;Latest
            </h3>
            <h5 className={styles.viewAll}>View All</h5>
          </div>
          
          <div className={styles.statusDisply}>
            <Slider {...settings}>
              {Feature_Card_Data.map((data, index) => (
                <ExploreStatusCard  
                  border_color = {data.border} 
                  width="84%"
                  avatar = {data.avatar}
                  content={data.content}
                />
              ))} 
            </Slider>
          </div>

          <div className={styles.bredcrumb}>
            <h3 className={styles.selectBread} >
              <img src="/dashboard/arrowDown.svg" style={{marginBottom:'-0.3rem'}} />&nbsp;Trending
            </h3>
            <h5 className={styles.viewAll}>View All</h5>
          </div>

          <div className={styles.statusDisply}>
            <Slider {...settings}>
              {Popular_Card_Data.map((data, index) => (
                <ExploreStatusCard  
                  border_color = {data.border} 
                  width="84%"
                  avatar = {data.avatar}
                  content={data.content}
                />
              ))} 
            </Slider>
          </div>

          <div className={styles.bredcrumb}>
              <h3 className={styles.selectBread} >
              <img src="/dashboard/fire.svg" style={{marginBottom:'-0.3rem'}} />&nbsp;Buzzing
              </h3>
              <h5 className={styles.viewAll}>View All</h5>
            </div>
          <div className={styles.statusDisply}>
            <Slider {...settings}>
              {Buzzing_Card_Data.map((data, index) => (
                <ExploreStatusCard  
                  border_color = {data.border} 
                  width="84%"
                  avatar = {data.avatar}
                  content={data.content}
                />
                ))} 
            </Slider>
          </div>
          
        </div>
      </div>
    );
  }




export default Explore;
