import React, { Suspense } from "react";
const LightTheme = React.lazy(() => import('./lightTheme'));
const DarkTheme = React.lazy(() => import('./darkTheme'));

export const ThemeSelector = ({ children }) => {
  const defaultTheme = "dark";
  return (
    <>
      <React.Suspense fallback={<></>}>
        {(defaultTheme === "light") && <LightTheme />}
        {(defaultTheme === "dark") && <DarkTheme />}
      </React.Suspense>
      {children}
    </>
  )
}