import Nav from "../../Nav/Nav";
import { useMediaQuery } from "react-responsive";
import HashtagContainer from "../Hashtag_Container/Hashtag_Container";
import BigBlogCard from "../Big_Blog_Card/Big_Blog_Card";
import BlogCardContainer from "../Blog_Card_Container/Blog_Card_Container";
import Footer from "../../Extras/Footer/Footer";
import { useContext, } from "react";
import axios from "axios";
import React from "react";
import BlogContext from "../Context";
import { CircularProgress } from "@material-ui/core";


function Blog_Landing_Screen () {
    
    const isDesktop = useMediaQuery({ query: "(min-device-width: 768px)" });
    const {blogs,loaded} = useContext(BlogContext)

    return (
        (loaded) ?
        <Nav hideTabs={ isDesktop ? false : true}>
        {blogs && blogs.map((blog) => (
            <React.Fragment>
                {
                    isDesktop ? (
                        <BigBlogCard 
                            title={blog[0]?.title}
                            caption={''}
                            date={blog[0]?.pubDate} 
                            media={blog[0]?.thumbnail}
                            tags={blog[0]?.categories}
                            id = {blog[0]?.id}
                        />
                    ) : (
                        <BlogCardContainer data ={blog}/>
                    )
                }
                <BlogCardContainer data ={blog.slice(1,4)}/>
            </React.Fragment>            
        ))}
    </Nav>
    :
    <div style={{display:'flex', justifyContent:'center', height:'56vh', marginTop:'30vh'}}> 
                <CircularProgress style={{color:'#00C6FF', height:'4rem', width:'4rem'}} color="#00C6FF" />
     </div>
    
    )
}


export default Blog_Landing_Screen;