import Nav from "../../Nav/Nav";
import { useMediaQuery } from "react-responsive";
import HashtagContainer from "../Hashtag_Container/Hashtag_Container";
import BigBlogCard from "../Big_Blog_Card/Big_Blog_Card";
import BlogCardContainer from "../Blog_Card_Container/Blog_Card_Container";
import Footer from "../../Extras/Footer/Footer";
import { useContext, useEffect, useState, } from "react";
import axios from "axios";
import React from "react";
import BlogContext from "../Context";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";


function Hashtag_landing_screen () {
    
    const isDesktop = useMediaQuery({ query: "(min-device-width: 768px)" });
    const [blog,setblogs] = useState([])
    const [loaded, setloaded] = useState(false)
    const {id} = useParams()
    const {hashtags} = useContext(BlogContext)
    useEffect(() => {
        const blog = axios.get(`https://blogzz-6980fb.ingress-erytho.easywp.com/wp-json/wp/v2/posts?tags=${id}`)

        
        Promise.all([blog]).then(res =>
            setblogs(res[0].data),
            setloaded(true)
            )
        
    }, [id])

    function chunk(array, size) {
        const chunked_arr = [];
        let index = 0;
        while (index < array.length) {
          chunked_arr.push(array.slice(index, size + index));
          index += size;
        }
        return chunked_arr;
      }
    const blogs = chunk(blog,4)
    return (
       

        
        <Nav hideTabs={ isDesktop ? false : true}>
        {loaded ?
        <>
        <div style={{marginLeft:'7.5%'}}> <HashtagContainer hashtags={ hashtags}/></div>
        {blogs && blogs.map((blog) => (
            
            <React.Fragment>
                <BigBlogCard 
                title={blog[0].title.rendered}
                caption={blog[0]}
                date={blog[0].date} 
                media={blog[0].featured_media}
                tags={blog[0].tags}
                />
                
                <BlogCardContainer data ={blog.slice(1,4)}/>
            </React.Fragment>
            
        ))}
        </>
        :
        <div style={{display:'flex', justifyContent:'center', height:'56vh', marginTop:'30vh'}}> 
                <CircularProgress style={{color:'#00C6FF', height:'4rem', width:'4rem'}} color="#00C6FF" />
            </div>
        }
        </Nav>
    )
}


export default Hashtag_landing_screen;