import classes from "./Title_Button_Block.module.css"




function Title_Button_Block2 () {
   
    return (
       <div>
         <div className={classes.heading2}>
             <h2 className={classes.heading2_h}> Better Do It < span class={classes.bold}>Together </span></h2>
         </div> 
         <p className={classes.heading2_p}>It is the easiest and fastest way to connect, help, collaborate and have fun with people around the globe by simply matching their thoughts.</p>
       </div>
       
    )
}


export default Title_Button_Block2;