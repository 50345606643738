import Nav from "../../Nav/Nav";
import SmallBlogCard from "../Small_Blog_Card/Small_Blog_Card";
import BigBlogCard from "../Big_Blog_Card/Big_Blog_Card";
import BlogCardContainer from "../Blog_Card_Container/Blog_Card_Container";
import { useMediaQuery } from "react-responsive";
import { useContext, useEffect, useState } from "react";
import classes from "./BlogScreen.module.css"
import { useParams } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";
import BlogContext from "../Context";


function Blog_Screen () {
    const {loaded,blogs,medium,image} = useContext(BlogContext)

    const isDesktop = useMediaQuery({ query: "(min-device-width: 768px)" });
    const {slug} = useParams()
    const [blog, setblog] = useState(false)


    useEffect(() => {
        setblog(medium[slug-1])
    }, [loaded,slug,medium])
    console.log(blogs[0])
    console.log(blog)
    return (


        <Nav hideTabs={ isDesktop ? false : true}>
          {(loaded && blog) ?
            <>
              <div class={classes.container}>

                <div class={classes.centered}>
                  <div >
                    <div className={classes.title}>
                      <h2>{blog.title}</h2>
                    </div>

                    {isDesktop ?
                      <div className={classes.details}>
                        <div className={classes.blog_details}>
                          <p className={classes.date}>{new Date(blog.pubDate).toDateString()}</p>
                          <div className={classes.author_box}>
                            <img src={image} className = {classes.authorImage} />
                            <p className={classes.author}>{blog.author}</p>
                          </div>
                          <p  className={classes.read_time}>{5} min Read</p>
                        </div>

                        <div className={classes.shareIcon}>
                          <img src="/icons/Upload.svg"></img>
                        </div>
                      </div>

                    :

                      <div className={classes.details}>
                        <div className={classes.author_box}>
                          <img src="/images/Profile_Pic.svg" />
                          <p className={classes.author}>{blog.author}</p>
                        </div>
                        <div className={classes.blog_details}>
                          <div className={classes.date_time}>
                            <p className={classes.date}>{new Date(blog.pubDate).toDateString()}</p>
                            <p  className={classes.read_time}>{5} min Read</p>
                          </div>
                          <div className={classes.shareIcon}>
                            <img src="/icons/Upload.svg"></img>
                          </div>
                        </div>
                      </div>

                    }


                    <div className={classes.caption}>
                      <p dangerouslySetInnerHTML={{ __html:blog.description }}>

                      </p>
                    </div>

                  </div>


                  <div  className={classes.blog_stats_box}>
                    <div className={classes.blog_stats}>
                      <div className={classes.author_box}>
                        <img src="/icons/Heart.svg" />
                        <p className={classes.stat}>460</p>
                      </div>
                      <div className={classes.author_box}>
                        <img src="/icons/comment.svg" />
                        <p className={classes.stat}>26</p>
                      </div>
                    </div>
                    <div className={classes.shareIcon}>
                      <img src="/icons/Upload.svg"></img>
                    </div>
                  </div>
                </div>
              </div>


              <h3 className={classes.sugestions_head}>
                Suggestions for <span className={classes.bold}>you</span>
              </h3>
              {blogs && blogs.length > 0 &&
                <BigBlogCard
                  title={blogs[0][0]?.title}
                  caption={' '}
                  date={blogs[0][0]?.pubDate}
                  media={blogs[0][0]?.thumbnail}
                  tags={blogs[0][0]?.categories}
                  id = {blogs[0][0]?.id}
                />
              }
              {isDesktop ?

                <BlogCardContainer data ={blogs[0].slice(1,4)}/>

              :

                <div className={classes.sugestions}>
                  <div className={classes.sugestion_card}>

                    <SmallBlogCard
                      img="/hand_and_pen.png"
                      hashtag="IncreaseProductivity"
                      title="How to become more productive with Bditto?"
                      caption="With a wide range of robust features and integrations, Bditto can easily turn into the central melting..."
                      date="July 17, 2021"
                    />
                  </div>

                  <div className={classes.sugestion_card}>
                    <SmallBlogCard
                      img="/hand_and_pen.png"
                      hashtag="IncreaseProductivity"
                      title="How to become more productive with Bditto?"
                      caption="With a wide range of robust features and integrations, Bditto can easily turn into the central melting..."
                      date="July 17, 2021"
                    />
                  </div>

                  <div className={classes.sugestion_card}>
                    <SmallBlogCard
                      img="/hand_and_pen.png"
                      hashtag="IncreaseProductivity"
                      title="How to become more productive with Bditto?"
                      caption="With a wide range of robust features and integrations, Bditto can easily turn into the central melting..."
                      date="July 17, 2021"
                    />
                  </div>
                </div>
              }
            </>:
            <div style={{display:'flex', justifyContent:'center', height:'56vh', marginTop:'30vh'}}>
              <CircularProgress style={{color:'#00C6FF', height:'4rem', width:'4rem'}} color="#00C6FF" />
            </div>}
        </Nav>

    )
}


export default Blog_Screen;
