import React, { useState } from "react";
import Nav from "../Nav/Nav";
import Row1 from "./Row1/Row1";
import Row2 from "./Row2/Row2";
import classes from "./About_Us.module.css"
import ValueBox1 from "./Value_Box/Value_Box1";
import ValueBox2 from "./Value_Box/Value_Box2";
import Violet_Button from "../Buttons/Violet_Button/Violet_Button";
import Team from "./Team/Team";
import Faq from "../Extras/Faqs/Faqs";
import { useMediaQuery } from "react-responsive";
import Footer from "../Extras/Footer/Footer";

function About_Us () {
    const [search, setsearchText]= useState("")
    const isDesktop = useMediaQuery({ query: "(min-device-width: 768px)" });
    const isDesktop2 = useMediaQuery({ minWidth: 1098 });
    return (
        <React.Fragment>

            <div className={classes.About_Us}>
                {isDesktop ? 
                <> <div className={classes.navbar}> <Nav/></div>
                    <div className={classes.header_image} style={{ backgroundImage: `url("/images/About_Us/Background.png")`}}>
                        
                    </div> 
                    </>
                    :
                    
                    <div>
                        <Nav hideTabs={true}></Nav>
                        <p className={classes.page_title} >More / <span className={classes.darken_text}>About Us</span></p>
                        <div className={classes.header_image} style={{ backgroundImage: `url("/images/About_Us/Background.png")`}}></div> 
                    </div>
            }
            </div>

            <div className={classes.text_box}>
                <h2 className={classes.heading}>We are driven by a <span className={classes.gradient_text}> simple idea!</span></h2>
                <p className={classes.caption}>Bditto is on a mission to bring people together and empower open communication. We believe you have interesting thoughts and it’s easy to create something together. As a member of the Bditto community, you’ll experience an exciting environment that supports teamwork, empowerment, flexibility, integrity, innovation, and feedback. It is this thinking and open approach, that allows us to create a unique culture where we thrive through collaboration. We do it and get better together.</p>
            </div>
            
            <Row1 />

            <Row2 />

            <div className={classes.Values_title}>
                <h2 className={classes.heading}>The <span className={classes.gradient_text}>Values </span>that we live by</h2>
            </div>

            <ValueBox1
                title="Fuel creativity"
                caption="Bditto is a place where we keep it light and work hard at the same time. A joy-fuelled company that believes in the power of creative energy."
                imageURL="/images/About_Us/Card_Icons/Icon1.svg" 
            />

            <ValueBox2
                title="Collaborate effectively"
                caption="Bditto is a team of collective genius, who believes that the best outcomes are achieved when people are able to create and collaborate in open, diverse, & inclusive environments."
                imageURL="/images/About_Us/Card_Icons/Icon2.svg" 
            />


            <ValueBox1
                title="Have fun. Grow."
                caption="Our culture is to share ideas, make bold bets and have fun while we grow. We will try something crazy, think differently and have a blast trying. "
                imageURL="/images/About_Us/Card_Icons/Icon4.svg" 
            />


            <ValueBox2
                title="Openness in action"
                caption="Bditto is a friendly, collaborative environment that moves quickly where ideas flow freely. We firmly believe sharing ideas and results with the whole team is essential to an open culture where our community can thrive."
                imageURL="/images/About_Us/Card_Icons/Icon3.svg" 
            />

            

            <div className={classes.Values_title}>
                <h2 className={classes.heading}>The Alpha <span className={classes.gradient_text}>Team</span></h2>
            </div>

            <Team />

            {isDesktop2 ? 
                <div>
                <div className={classes.circularCard1}>
                    <img src="/images/About_Us/CircularCard1_Image.svg" />
                    <div>
                        <h2 className={classes.teamHead} >Be a Part of the Team</h2>
                        <p className={classes.para}>If you are passionate about our mission, Bditto is the place for you. We're a start-up and we need people who share the same passion and excitement! You will be working alongside talented people who brought Bditto up from just an idea to what it is now!.</p>
                        <div className={classes.para_btn}>
                            <a href="mailto:hello@bditto.com">
                                <Violet_Button className={classes.para_btn}>Say Hello</Violet_Button>
                            </a>
                        </div> 
                    </div>
                    <img src="/images/About_Us/CircularCard2.svg" />
                </div>

                <div className={classes.circularCard2}>
                    <img src="/images/About_Us/CircularCard2.svg" />
                </div>
                </div>
             :

             <div>
                  <div>
                        <h2 className={classes.teamHead} >Be a Part of the Team</h2>
                        <p className={classes.para}>If you are passionate about our mission, Bditto is the place for you. We're a start-up and we need people who share the same passion and excitement! You will be working alongside talented people who brought Bditto up from just an idea to what it is now!.</p>
                        <div className={classes.para_btn}>
                            <Violet_Button className={classes.para_btn}>Say Hello</Violet_Button>
                        </div> 

                        <div className={classes.circle_container1}>
                            <img className={classes.circleImage} src="/images/About_Us/CircularCard1_Image.svg" />
                        </div>
                        <div className={classes.circle_container2}>
                            <img className={classes.circleImage} src="/images/About_Us/CircularCard2_Image.svg" />
                        </div>
                        <div className={classes.circle_container3}>
                            <img className={classes.circleImage} src="/images/About_Us/CircularCard2_Image.svg" />
                        </div>
                  
                    </div>
             </div>

            }
            <div className={classes.heading1} style={{marginTop:'25vh'}}>
    <h2 className={classes.heading1_h} style={{width:'90%'}}> How can we < span class={classes.bold}>help you&nbsp;?</span></h2>
        <div className={classes.searchContainer}>
        <img src="/icons/search.svg"  className={classes.searchIcon}/>
        <input placeholder="Ask a question..." value={search} onChange={e =>setsearchText(e.target.value)} className={classes.seachBox} />
        <button className={classes.searchBtn}>Search</button>
        </div>
    </div> 
                    <Faq searchText={search} />

        </React.Fragment>
        
    )
}


export default About_Us;