import React from "react";
import Nav from "../Nav/Nav";
import classes from "./Privacy.module.css";

const Privacy = () => {
  return (
    <div>
      <Nav />
      <div className={classes.container}>
        <div className={classes.header}>
          <h2 className={classes.heading}>
            Bditto <span className={classes.bold}>Privacy</span> Policy
          </h2>
          <p className={classes.caption}>
            Last updated and effective: March 15, 2023
          </p>
        </div>

        <div>
          <div className={classes.para}>
            <h3 className={classes.paraTitle}>Welcome to Bditto</h3>
            <p className={classes.paraContent}>
              Bditto Limited UK company, provides a social online and mobile
              chat platform via the BDITTO website (the “Site”), and related
              Internet services (collectively, the “Service(s)”). The Service is
              operated by Bditto Limited UK company(the “Company”, “we” or “us”)
              for users of the Service (“you”). This Privacy Policy sets forth
              our policy with respect to information that is collected from
              visitors to the Site and users of the Webapp and/or the Services.
              Under applicable law, Bditto Limited UK company is the “data
              controller” of personal data collected through the Services.
            </p>
          </div>

          <div className={classes.para}>
            <h3 className={classes.paraTitle}>Information we collect</h3>
            <p className={classes.paraContent}>
              When you interact with us through the Services, we may collect
              information from you, as further described below: Information You
              Provide: We collect information from you when you voluntarily
              provide such information, such as when you register for access to
              the Services or use certain Services. Information we collect may
              include but not be limited to username, name, email address, age,
              location, and any messages, images, transient VOIP data (to enable
              communication delivery only) or other content you send via the
              chat feature.
            </p>
          </div>

          <div className={classes.para}>
            <h3 className={classes.paraTitle}>Data We Collect Automatically</h3>
            <p className={classes.paraContent}>
              When you interact with us through the Services, we receive and
              store certain information such as an IP address, device ID, and
              your activities within the Services. We may store such information
              or such information may be included in databases owned and
              maintained by affiliates, agents or service providers. The
              Services may use such information and pool it with other
              information to track, for example, the total number of visitors to
              our Site, the number of messages users have sent, as well as the
              sites which refer visitors to Bditto.
            </p>
          </div>

          <div className={classes.para}>
            <h3 className={classes.paraTitle}>Aggregated Information</h3>
            <p className={classes.paraContent}>
              When you interact with us through the Services, we receive and
              store certain information such as an IP address, device ID, and
              your activities within the In an ongoing effort to better
              understand and serve the users of the Services, we may conduct
              research on our customer demographics, interests and behavior
              based on the information collected. This research may be compiled
              and analyzed on an aggregate basis, and we may share this
              aggregate data with our affiliates, agents and business partners.
              We may also disclose aggregated user statistics in order to
              describe our services to current and prospective business
              partners, and to other third parties for other lawful purposes. .
              We may store such information or such information may be included
              in databases owned and maintained by affiliates, agents or service
              providers. The Services may use such information and pool it with
              other information to track, for example, the total number of
              visitors to our Site, the number of messages users have sent, as
              well as the sites which refer visitors to Bditto.
            </p>
          </div>

          <div className={classes.para}>
            <h3 className={classes.paraTitle}>Info through other Services</h3>
            <p className={classes.paraContent}>
              You may give us permission to collect your information in other
              services. For example, you may connect a social networking service
              ("SNS") such as Google, Facebook or Twitter to your BDITTO
              account. When you do this, it allows us to obtain information from
              those accounts (for example, your friends or contacts).
            </p>
          </div>

          <div className={classes.para}>
            <h3 className={classes.paraTitle}>Cookies</h3>
            <p className={classes.paraContent}>
              We employ cookies and similar technologies to keep track of your
              local computer’s settings such as which account you have logged
              into and notification settings. Cookies are pieces of data that
              sites and services can set on your browser or device that can be
              read on future visits. We may expand our use of cookies to save
              additional data as new features are added to the Service. In
              addition, we use technologies such as web beacons and single-pixel
              gifs to record log data such as open rates for emails sent by the
              system.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
