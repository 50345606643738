import React from "react";
const faqs = [[
  {
    head: "What is Bditto.com?",
    content:
      "Have you ever been wondering what other people are thinking, Bditto is the place to be. It's the first and only platform to share your thoughts in real-time and instantly get connected to like minded people.",
  },
  {
    head: "Why should I use Bditto?",
    content:
      "It is the easiest and fastest way to connect, help, collaborate and have fun with people around the globe by simply matching their thoughts.",
  },
  {
    head: "What does the name Bditto stand for?",
    content: "Bditto stands for Better Do It Together",
  },
  {
    head: "What do you do with my data?",
    content:
      "Please refer to our Privacy Policy and Terms of Use for more details about how we treat your data.",
  },
  {
    head: "How to start with Bditto?",
    content: (
      <ol>
        <li>Navigate to www.bditto.com in your web browser.</li>{" "}
        <li>
          Under Sign Up, enter your personal information and desired password,
          then click Sign Up.
        </li>
        <li>
          You can then follow the directions on the screen and get started.
        </li>
      </ol>
    ),
  },
  {
    head: "Is posting personal information ok?",
    content:
      "NO. Bditto is a pretty open and free speech place, but it is not ok to post someone's personal information, or post links to personal information. This includes links to public pages and screenshots of pages with the names still legible. We all get outraged by the ignorant things people say and do online, but witch hunts and vigilantism hurt innocent people and certain individual information, including personal info found online is often false. Posting personal information will get you banned. Posting professional links to contact a congressman or the CEO of some company is probably fine, but don't post anything inviting harassment, don't harass, and don't cheer on or vote up obvious vigilantism.",
  },
  {
    head: "Is it okay to create multiple accounts?",
    content:
      "You can create multiple accounts as long as you have a unique email id for each account and are not doing any false self-promotions or creating a fake identity.",
  },
  {
    head: "Why should I create a status?",
    content:
      "It helps to get discovered by other users across the globe and doubles your chance of getting in touch with each other.",
  },
  {
    head: "Can I maintain unlimited statuses?",
    content: (
      <>
        To ensure that the discussions are active and up to date, Bditto allows
        you to engage in a maximum of 200 status conversations (including own
        and other statuses). Once you reach the limit you can either delete
        statuses or even deactivate if you don’t want to lose content. The limit
        does not apply to individual private chats. <br />
        Our recommendation, thoughts get outdated pretty quick, so it`s more
        about posting new statuses than keeping the old ones.
      </>
    ),
  },
  {
    head: "What’s the difference between a private and group chat?",
    content:
      "Group chat is an open and public discussion channel where any user can join based on their matched statuses or by exploring others. One can also have private chats in Bditto, but only with accepted friends, and these conversations are one to one and are private.",
  },
  {
    head: "How do I start a private chat? ",
    content:
      "Click on someone’s profile and send a friend request. if the friend request has been approved, then both are able to start a private chat.      ",
  },
  {
    head: "How do I remove someone from a group?",
    content:
      "If you feel like someone is not following the Bditto conversation rules, browse the list of users by selecting the status bar and click on the relevant user profile pic and hit the remove button.",
  },
  {
    head:
      "Will my status in a Bditto group be deleted after leaving the Group?",
    content:
      "If you delete your own status, it still remains on Bditto for all other users in the group. However, if the user wants to delete their chats, they can do so by deleting one by one within the group conversation.",
  },
  {
    head: "What happens if I delete my account?",
    content: (
      <>
        When you delete your Bditto account, a few things happen:
        <ul>
          <li>
            Your profile is permanently removed from Bditto. That includes your
            profile information, bookmarks, contacts you may have set up. Even
            if someone has a direct link to your profile, they’ll get a message
            that says the account has been deleted.
          </li>
          <li>
            Your username is permanently removed from Bditto. You won’t be able
            to log in to Bditto with your old username and password. Also,
            nobody (including you) will be able to create a new account with
            your old username.
          </li>
          <li>
            Your posts and comments become unattributed. Any posts or comments
            you made from your deleted account stay on Bditto, but people can’t
            see who they came from. (If you have posts or comments you’d like to
            remove, you need to delete them before you delete your account.)
          </li>
          <li>
            Your messages and chats become unattributed. Any messages you sent
            or chats you took part in from your deleted account stay on Bditto,
            but people can’t see who they came from.
          </li>
        </ul>
      </>
    ),
  },
  {
    head: "Any other questions?",
    content:
      "This is the fun part, directly post your question or problem on Bditto with: #Bditto #support",
  },
  ],
  [
    {
      head: "Is there a free demo of Bditto available??",
      content:
        "Yes, any user can sign up and login into the platform to explore Bditto platform for free. For organizations and institutions, please contact us for further details",
    },
    {
      head: "Is there a trial period I can avail??",
      content:
        "For Organizations and Institutions, there is 1 month free trial available.",
    },
    {
      head: "How many pricing plans are there?",
      content:
        "Pricing depends on the size of the organization and its intended users. Please contact us for custom pricing",
    },
    {
      head: "How do I identify which plan is suitable for me?",
      content:
        "Based on your requirement and expected usage, we would be happy to suggest the best pricing plan with required features",
    },
    {
      head: "Can I change my plan while I have an ongoing subscription?",
      content:
        "Right now, we only have a standard plan for Bditto. There is a possibility to update to the premium plan once we roll out some new premium features",
    },
    {
      head: "Do I have to buy it for a month only or can I buy it for a longer period?",
      content:
        "The minimum duration plan is for one month and it is renewable. On the other hand, you can buy a subscription for a year or 2 year as well for a discounted price. ",
    },
    {
      head: "Is there any discount on subscription plans if I buy them for a long period?",
      content:
        "Yes, annual plans come with discounted prices.You can contact us for a quote on annual plans.",
    },
  ],
  [
    {
      head: "How is Bditto helpful for educational institutes?",
      content:
        "Bditto will change how people collaborate in classrooms, boardrooms and conference rooms. It will make people work together more effectively by making their ideas come alive on the fly.        ",
    },
    {
      head: "What all opportunities can Bditto help with for the students?",
      content:
        "Bditto is the fastest way to get to the line of thinking. With Bditto you can quickly create a shared workspace and then everyone instantly sees what others are working on and discuss ideas with them in real time.",
    },
    {
      head: "Is there any discounted plan for colleges and universities?",
      content:
        "Yes, we have discounted plans for educational and charitable institutions. Please contact us for pricing ",
    },
    {
      head: "What is the minimum duration plan that an educational institute can buy?",
      content:
        "Minimum duration would be one month and you are flexible to extend or cancel any time. We also offer 1 month free trial option.",
    },
    {
      head: "Can Students collaborate in Bditto with other colleges/universities ?",
      content:
        "No, currently Bditto allows collaboration only within a college/university.",
    },
  ],
  [
    {
      head: "Is my data secure on Bditto?",
      content:
        "Yes, we respect the privacy of our users, so the data is secured",
    },
    {
      head: "If I link my google or social media account while signing up, will that account remain secure?",
      content:
        "Yes, that will remain secure",
    },
    {
      head: "Where is the data stored?",
      content:
        "Date is stored and secured in the cloud provider offering full security",
    },
    {
      head: "Are the communications done on the private chats encrypted?",
      content:
        "Yes, all the communications done in the private chats are encrypted",
    },
    {
      head: "What happens to my account and data associated with it if the account gets deleted?",
      content:
        "We’ll be sorry to see you go but we respect your wishes. If the account is deleted from the platform, all your personal data such as bookmarks, profile information, your contacts will be deleted. Your connections will be notified that your account has been deleted. However the posts or comments will not be deleted but other users won’t be able to check about who posted them. If you want to delete them before you leave the platform, you’ll have to do it manually",
    },
    {
      head: "How do I delete any personal information from Bditto?",
      content:
        "All your profile details will be deleted automatically when you delete your account. Any thoughts posted in the public groups and chats are still available for others until they are deleted manually by you",
    },
  ],
]

export default faqs;
