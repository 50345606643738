import classes from "./Color_Card.module.css"



function Color_Card ({ color, imageURL,caption, mobileFLex }) {
    
    let color_class;
    if(color  == 'yellow')
        color_class = classes.yellow;
    else if(color  == 'pink')
        color_class = classes.pink;
    else if(color  == 'blue')
        color_class = classes.blue;

    const mobile_flex_class = mobileFLex == 'row' ? classes.flex_row : classes.flex_row_reverse;

    return (
       <div className={`${classes.color_card_container} ${mobile_flex_class}`}>
            <div className={`${classes.Color_Card} ${color_class}`}>
                <div className={classes.image}>
                    <img className={classes.img} src={imageURL} />
                </div>
            </div> 
            <p className={classes.caption}>{caption}</p>
       </div>
      
    )
}


export default Color_Card;