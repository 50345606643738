import classes from "./Card.module.css"




function Card ({title, text, imageURL}) {
   
    return (
       
        <div className={classes.card}>
                <div className={classes.image_card}>   
                    <div className={classes.image}>
                        <img  src={imageURL} />
                    </div>
                </div>
                <div className={`${classes.text_box}`}>
                    <h3 className={classes.text_title}>
                        {title}
                    </h3>
                    <p className={classes.text}>
                        {text}
                    </p>
                </div>
        </div> 
    )
}


export default Card;