import classes from "./Violet_Border_Button.module.css";

function Violet_Border_Button (props) {
    return (
      <button className={classes.Violet_Border_Button}>
        <p className={classes.button_text}>{props.children}</p>
      </button>
    );
}


export default Violet_Border_Button;