import React from 'react'
import styles from "./StatusCard.module.css"
const SmallStatusCard = ({border_color, width, item}) => {
    return (
        <div  className={`${styles.feature_card} `} style={{width:width, border:  border_color == "chat"?'1px solid rgba(0, 200, 255, 0.605)':'none' }}>
            <img src="/dashboard/options.svg" style={{marginLeft:'auto'}} />
            <div className={styles.content}>
                <p>{item}</p>
            </div>
            <div className={styles.info_box}>
                <div>
                    <div>
                        +15 users
                    </div>
                </div>
                <div className={styles.info}>
                <div className={styles.info_element}>
                <img src="/dashboard/love.svg" style={{marginTop:'-0.12rem'}} />&nbsp;99
                    </div>
                    <div className={styles.info_element}>
                    <img src="/dashboard/msg.svg" style={{marginTop:'-0.1rem'}} />&nbsp;12
                    </div>
                    <div>
                    <img src="/dashboard/time.svg" style={{marginBottom:'-0.15rem'}} /> 3h
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default SmallStatusCard
