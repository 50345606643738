import classes from "./Row1.module.css"


function Row1 () {
    return (
        <div className={classes.row1}>
            <div className={classes.textbox}>
               <h1 className={classes.title}> Bditto <span className={classes.gradient_text}> Labs</span> </h1>
               <p className={classes.caption}>Research and innovation is the driving force behind our collaboration and connectivity.</p>
            </div>
            <div>
                <img className={classes.image} src="/images/Bditto_Labs/Row1.jpg"  />
            </div>     
        </div>
    )
}


export default Row1;