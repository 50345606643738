import ColorCard from "./Color_Card/Color_Card";
import classes from "./Color_Cards_Block.module.css"



function Color_Card_Block () {
    
   
    return (
       <div className={classes.color_card_block}>
             <ColorCard 
                color="yellow"
                imageURL="images/Color_Cards/CardImage_1.png"
                caption = "Open Collaboration"
                mobileFLex = "row"
            />

            <ColorCard 
                color="pink"
                imageURL="images/Color_Cards/CardImage_2.png"
                caption = "Helping businesses"
                mobileFLex = "row_reverse"
            />

            <ColorCard 
                color="blue"
                imageURL="images/Color_Cards/CardImage_3.png"
                caption = "One-one encrypted "
                mobileFLex = "row"
            />
       </div>
      
    )
}


export default Color_Card_Block;