import classes from "./Image_Card.module.css"



function Image_Card ({ corner, imageURL }) {
    
    const border_class = corner == 'left' ? classes.left : classes.right;
    return (
       
        <div>
            <img className={border_class} src={imageURL} />
        </div> 
    )
}


export default Image_Card;