import classes from "./Education.module.css"
import { useMediaQuery } from "react-responsive";
import VioletButton from "../Buttons/Violet_Button/Violet_Button";
import Nav from "../Nav/Nav";
import Faq from "../Extras/Faqs/Faqs";
import Footer from "../Extras/Footer/Footer";
import Card from "./Card/Card";
import { useState } from "react";
import { useHistory } from "react-router-dom";

function Education () {
    
    const isDesktop = useMediaQuery({ minWidth: 898 });
    const [search, setsearchText]= useState("")
    const Screens_image_url = isDesktop ? "/images/Education/3Screens.png" : "/images/Education/3Screens_small.png";
    const history = useHistory();
    return (
        <Nav hideTabs={ isDesktop ? false : true}>
            <div className={classes.Education}>

                {isDesktop ? 

                <div>
                    
                    <div className={classes.row1}>
                        <img src="/images/Education/Circle4.jpg" className ={classes.circle1}/>
                        <img src="/icons/dot1.svg"/>
                        <h2 className={classes.heading}>
                            Bditto for Education
                        </h2>
                        <img src="/icons/dot2.svg"/>
                        <img src="/images/Education/Circle1.jpg" className ={classes.circle2} />
                    </div>

                    <div className={classes.row2}>
                        <h2 className={classes.heading}>
                            Where <span className={classes.gradient_text}>Students </span>connect
                        </h2>   
                        <p className={classes.caption}>We Simplify Student Life.Work Together.Learn together.</p>
                    </div>
                        
                    <div className={classes.row3}>
                        <img  src="/images/Education/Circle2.jpg" className ={classes.circle3} />
                        <img src="/icons/dot3.svg"/>
                        <div className={classes.row3_button}  onClick={()=> history.push("/get-in-touch")}>
                            <VioletButton>Get in Touch <span className={classes.button_arrow}>&#8594;</span></VioletButton>
                        </div>
                        <img src="/icons/dot4.svg"/>
                        <img  src="/images/Education/Circle3.jpg" className ={classes.circle4}/>
                    </div>
                </div> 

                :

                <div>
                    <div className={classes.row1}>
                        <img src="/images/Education/Circle4.jpg" className ={classes.circle1}/>
                        <img src="/images/Education/Circle1.jpg" className ={classes.circle2}/>
                    </div>

                    <div className={classes.row2}>
                        <h2 className={classes.heading}>
                            Bditto for Education <br />
                            Where <span className={classes.gradient_text}>Students </span>connect
                        </h2>   
                        <p className={classes.caption}>We Simplify Student Life.Work Together.Learn together.</p>
                        <div className={classes.row3_button}  onClick={()=> history.push("/get-in-touch")}>
                            <VioletButton>Get in Touch <span className={classes.button_arrow}>&#8594;</span></VioletButton>
                        </div>
                    </div>
                        
                    <div className={classes.row3}>
                        <img  src="/images/Education/Circle2.jpg" className ={classes.circle3}/>
                        <img  src="/images/Education/Circle3.jpg" className ={classes.circle4}/>
                    </div>
                </div> }




                <div className={classes.section2}>
                    <div className={classes.section2_heading}>
                       <h2 className={`${classes.heading} ${classes.align_left}`}> Discover a world of < span class={classes.gradient_text}>opportunities </span></h2>
                    </div>
                    <p className={classes.caption2}>Bditto is an exclusive platform for students to find, network, collaborate and innovate with initiatives they are passionate about: from internships, jobs and projects to start-ups.</p>


                   
                    <div className={classes.card_container}>
                        <div className={classes.card_row}>
                            <Card 
                                title="Collaborate within your university"
                                text="Bditto is a collaboration and communication tool that helps students stay in touch with their study groups, classmates, and teaching staff instantly."
                                imageURL="/images/Education/Icons/Icon1.svg"
                            />

                            <Card 
                                title="Studying just got better"
                                text="It's where you can meet with anyone to exchange ideas, ask questions, study together or learn from one another."
                                imageURL="/images/Education/Icons/Icon2.svg"
                            />
                         </div>


                         <div className={classes.card_row}>
                            <Card 
                                title="Be More Productive"
                                text="We designed Bditto to be productive, safe and simple for everyone from students to teachers."
                                imageURL="/images/Education/Icons/Icon3.svg"
                            />

                            <Card 
                                title="Learn more. Share more."
                                text="It is a new way of interaction between students and professors and for students to interact with each other seamlessly."
                                imageURL="/images/Education/Icons/Icon4.svg"
                            />
                         </div>


                         <div className={classes.card_row}>
                            <Card 
                                title="Connect like never before"
                                text="Bditto is created to enable students connect with each other based on their interests according to whatever topics they would like to share and discuss about. "
                                imageURL="/images/Education/Icons/Icon5.svg"
                            />

                            <Card 
                                title="Find the best opportunities"
                                text="Bditto helps you find interesting projects, internships, and jobs by verified companies."
                                imageURL="/images/Education/Icons/Icon6.svg"
                            />
                         </div>
                    </div>
                </div>



                    <div className={classes.Screens}>
                        <div>
                            <img src={Screens_image_url} />
                        </div>

                        <div className={`${classes.text_box2}`}>
                            <h2 className={classes.heading2}>What Bditto has in store for <span className={classes.gradient_text}>you&nbsp;?</span></h2>
                            <p className={classes.text2}>Bditto is an exclusive platform for students to find, network, collaborate and innovate with initiatives they are passionate about: from internships, jobs and projects to start-ups.</p>
                            <div  onClick={()=> history.push("/get-in-touch")}>
                                <VioletButton>Get in Touch <span className={classes.button_arrow}>&#8594;</span></VioletButton>
                            </div>
                        </div>
                    </div>
                    <div className={classes.heading1} style={{marginTop:'35vh'}}>
    <h2 className={classes.heading1_h} style={{width:'90%'}}> How can we < span class={classes.bold}>help you ?</span></h2>
        <div className={classes.searchContainer}>
        <img src="/icons/search.svg"  className={classes.searchIcon}/>
        <input placeholder="Ask a question..." value={search} onChange={e =>setsearchText(e.target.value)} className={classes.seachBox} />
        <button className={classes.searchBtn}>Search</button>
        </div>
    </div> 
                    <Faq searchText={search} />


            </div>
        </Nav>
    )
}


export default Education;