import classes from "./Title_Card.module.css"



function Title_Card ({ text, corner }) {
    
    const border_class = corner == 'left' ? classes.left : classes.right;
    
    return (

        <div className={`${classes.Title_Card} ${border_class}`}>
            {text}
        </div>

    )
}


export default Title_Card;