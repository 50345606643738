import classes from './App.module.css';
import BlogLandingScreen from './Components/Blog/Blog_Landing_Screen/Blog_Landing_Screen';
import BlogScreen from './Components/Blog/BlogScreen/BlogScreen';
import BdittoLabs from './Components/Bditto_Labs/Bditto_Labs';
import MainLandingScreen from './Components/Main_Landing_Screen/Main_Landing_Screen';
import {BrowserRouter as Router, Switch, Route} from  "react-router-dom";
import { ThemeSelector } from "./Themes/ThemeSelector";
import GetInTouch from './Components/GetInTouch/getInTouch';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import Pricing from './Components/Pricing/Pricing';
import AboutUs from './Components/About_Us/About_Us';
import Education from './Components/Education/Education';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from './Components/Dashboard/Home';
import MediaQuery from "react-responsive";
import { IntlProvider } from "react-intl";
import MobileNav from './Components/Nav/MobileNav';
import { useMediaQuery } from "react-responsive";
import Footer from './Components/Extras/Footer/Footer';
import BlogProvider from './Components/Blog/Context/Context';
import Hashtag_landing_screen from './Components/Blog/Blog_Landing_Screen/Hashtags_landing_screen';
import ScrollToTop from './Components/Extras/ScrollToTop';
import Privacy from './Components/Privacy/Privacy';
import UserAgreement from './Components/User_Agreement/User_Agreement';
import ContentPolicy from './Components/Content_Policy/Content_Policy';
import { useState } from 'react';
import NavMore from './Components/Nav/NavMore';

function App() {
  const [popover, setPopover] = useState(false);
  const isMobile =  useMediaQuery({ maxWidth : 868 });
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#7F89BE",
      },
      green: {
        main: "#F5F94A",
      },
      yellow: {
        main: "#FF5B5B",
      },
    },
  });
  return (
    <div className={classes.App}>
      <div className={classes.AppContainer}>
        <ThemeSelector>
          <ThemeProvider theme={theme}>
            <IntlProvider>
              <Router>
                <ScrollToTop />

                <MediaQuery minDeviceWidth={900}>
                  <div style={{paddingBottom: isMobile?"68px":'0px'}}>
                    <Switch >
                      <Route path="/get-in-touch" component={GetInTouch}/>
                      <Route path="/pricing" component={Pricing}/>
                      <Route path="/lab" component={BdittoLabs}/>
                      <Route path="/aboutus" component={AboutUs}/>
                      <Route path="/education" component={Education}/>
                      <Route exact path="/" component={MainLandingScreen}/>
                      <Route path="/home" component={Home}/>
                      <Route path="/privacy" component={Privacy}/>
                      <Route path="/userAgreement" component={UserAgreement}/>
                      <Route path="/contentPolicy" component={ContentPolicy}/>
                      <BlogProvider>
                        <Route exact path="/blog" component={BlogLandingScreen}/>
                        <Route exact path="/hashtag/:id" component={Hashtag_landing_screen}/>
                        <Route exact path="/blogs/:slug" component={BlogScreen}/>
                      </BlogProvider>
                    </Switch>
                    <Footer />
                  </div>
                </MediaQuery>


                <MediaQuery maxDeviceWidth={868}>
                  {
                    popover ? (<NavMore popover={popover} setPopover={setPopover}/>) : (
                      <div style={{paddingBottom: isMobile?"68px":'0px'}}>
                        <Switch >
                          <Route path="/get-in-touch" component={GetInTouch}/>
                          <Route path="/pricing" component={Pricing}/>
                          <Route path="/lab" component={BdittoLabs}/>
                          <Route path="/aboutus" component={AboutUs}/>
                          <Route path="/education" component={Education}/>
                          <Route exact path="/" component={MainLandingScreen}/>
                          <Route path="/home" component={Home}/>
                          <Route path="/privacy" component={Privacy}/>
                          <Route path="/userAgreement" component={UserAgreement}/>
                          <Route path="/contentPolicy" component={ContentPolicy}/>
                          <BlogProvider>
                            <Route exact path="/blog" component={BlogLandingScreen}/>
                            <Route exact path="/hashtag/:id" component={Hashtag_landing_screen}/>
                            <Route exact path="/blogs/:slug" component={BlogScreen}/>
                          </BlogProvider>
                        </Switch>
                        <Footer />
                      </div>
                    )
                  }
                  <MobileNav popover={popover} setPopover={setPopover} />
                </MediaQuery>
              </Router>
            </IntlProvider>
          </ThemeProvider>
        </ThemeSelector>
      </div>
    </div>
  );
}

export default App;
