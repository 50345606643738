import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import Styles from "./Navbar.module.css";
import ThoughtIcon from "../../Extras/Icons/ThoughtIcon";

const useStyles = (theme) => ({
  menuButton: {
    cursor: "pointer",
    height: "100%",
    color: "var(--secondary)",
    fontFamily: "var(font-family)",
    fontSize: "16px",
    fontWeight: "400",
    textTransform: "none",
    marginLeft: "20px",
    borderRadius: "0",
    "&:hover": {
      borderBottom: "3px rgba(0, 200, 255, 0.705) solid",
      color: "var(--heading-color2)",
      paddingBottom:'6px',
    },
  },
  selectedBtn: {
    cursor: "pointer",
    height: "100%",
    color: '#fff',
    fontWeight: "400",
    fontFamily: "var(font-family)",
    fontSize: "1.05rem",
    marginLeft: "20px",
    borderRadius: "0",
    paddingBottom:'6px',
    borderBottom: "3px rgba(0, 200, 255, 0.705) solid",
   
  },
  userData: {
    cursor: "pointer",
    display: "flex",
    padding: "0.25rem",
    justifyContent: "left",
    alignItems: "center",
    width: "14rem",
    [theme.breakpoints.down("xs")]: {
      width: "4rem",
    },
  },
  avatar: {
    fontSize: "40px",
    color: "#c4c4c4",
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },

});

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationShow: false,
      isOnline: false,
      isOpen: false,
      notification: [],
      newMessages: false,
      loading: false,
      newNoti: false,
      selectedValue: "online",
    };
    this.initialRef = React.createRef();
  
  }

  
  render() {
    const { classes } = this.props;
    const isMobile = window.screen.width < 600;
    let currentPath = this.props.dash
    return (
      <div className={Styles.container}>
        <div className={Styles.navbarContainer}>
          <div className={Styles.left}>
           
              <Box style={{width: '89px',height: '43px'}}>
                <img src="/logoMain.svg" alt="logo" style={{height: '50px',}}/>
              </Box>
         
            <div className={Styles.links}>
              <span
                onClick={() => this.props.changeDash("/")}
                className={currentPath === "/" ? classes.selectedBtn : classes.menuButton}

              >  <span className={Styles.icons}> {currentPath === "/" ?
            <ThoughtIcon color="white" /> :
              <ThoughtIcon color="#7F89BE" />
                 }</span>
                 <span className={Styles.iconsText}><FormattedMessage id="thoughts" defaultMessage={`Thoughts`} />
            </span>  </span>
              <span 
                id="Explore"
                onClick={() => this.props.changeDash("explore")}
                className={currentPath.startsWith("explore") ? classes.selectedBtn : classes.menuButton}>
                {currentPath === "explore" ? <img src="/dashboard/exploreWhite.svg"  htmlColor="#fff"/> :<img src="/dashboard/explore.svg"  htmlColor="#fff"/>}
                <FormattedMessage id="explore" defaultMessage={`Explore`} />
              </span>
              <span
                id="newsfeed"
                onClick={() => this.props.changeDash("myfeed")}
                className={currentPath.startsWith("myfeed") ? classes.selectedBtn : classes.menuButton}
              >
                 {currentPath === "myfeed" ? <img src="/dashboard/feedWhite.svg" /> :<img src="/dashboard/feed.svg" />}
                <FormattedMessage id="myFeed" defaultMessage={`My Feed`} />
              </span>
             
                <span
                  id="Chats"
                  onClick={() => {
                    this.props.changeDash("chat");
                  }}
                  className={currentPath.startsWith("chat") ? classes.selectedBtn : classes.menuButton}
                >   {currentPath === "chat" ? <img src="/dashboard/chatWhite.svg" /> :<img src="/dashboard/chat.svg" />}
                  <FormattedMessage id="chats" defaultMessage={`Messenger`} />
                </span>
              
            </div>
          </div>

          <div className={Styles.right}>
              <div className={Styles.search} id="Search">
              <img src="/dashboard/search.svg" htmlColor="#fff" /> <img src="/dashboard/location.svg" /><img src="/dashboard/bell.svg" />
              </div>
                  <span className={Styles.user}>
                    <Avatar style={{width:'2rem' , height:'2rem', background: "#C2185B", fontSize: "1rem"}}>G</Avatar>
                    <h2 className={Styles.username}>Grace</h2> 
                    <img src="/dashboard/chevronDown.svg" />
                  </span>
          </div>
        </div>
      </div>
    );
  }
}

export default (withStyles(useStyles)(Navbar))
