import classes from "./Row3.module.css"


function Row3 () {
    return (
        <div className={classes.container}>
            <div className={classes.row3}>
                <div>
                    <img className={classes.image} src="/images/Bditto_Labs/Row3.jpg"  />
                </div>   

                <div className={classes.textbox}>
                    <h1 className={classes.title}> <span className={classes.bold}> Intelligent solutions</span> at the speed of thought</h1>
                    <p className={classes.caption}>Enabling companies to get real time insights for instant decision making and provide customized business insights according to user intent.</p>
                </div>  
            </div>
        </div>
    )
}


export default Row3;