import Nav from "../Nav/Nav";
import { useState } from "react";
import classes from "./Main_Landing_Screen.module.css";
import TextCarousel from "./Text_Carousel/Text_Carousel";
import ImageTextCarousel from "./Image_Text_Carousel/Text_Carousel";
import TitleButtonBlock1 from "./Title_Button_Block/Title_Button_Block";
import CharacterTextGrid from "./Character_Text_Grid/Character_Text_Grid";
import TitleButtonBlock2 from "./Title_Button_Block/Title_Button_Block2";
import FeatureScrollCard from "./Feature_Scroll_Card/Feature_Scroll_Card";
import ImageGrid from "./Image_Grid/Image_Grid";
import ColorCardsBlock from "./Color_Cards_Block/Color_Cards_Block";
import TitleButtonBlock4 from "./Title_Button_Block/Title_Button_Block4";
import TestimonialCarousel from "./Testimonial_Carousel/Testimonial_Carousel";
import Home from "../Dashboard/Home";
import Faq from "../Extras/Faqs/Faqs";
import Footer from "../Extras/Footer/Footer";
import { useMediaQuery } from "react-responsive";
import MobileNav from "../Nav/MobileNav";

function Main_Landing_Screen() {
  // Data for carousel
  const [search, setsearchText] = useState("");
  const isDesktop = useMediaQuery({ minWidth: 788 });
  const isDash = useMediaQuery({ minWidth: 908 });
  const displayWave = useMediaQuery({ minWidth: 1188 });
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const carouselSlidesData = [
    // {
    // content:
    //     "By 2022, 70% of teams will rely on Workstream Collaboration",
    // author: "By 2022, 70% of teams will rely on Workstream Collaboration as their primary means of communicating, coordinating, and sharing information. By 2023, Gartner assumes that 75% of organizations using Workstream Collaboration will have it fully extended to all employees - up from 45% in 2019.",
    // source: ""
    // },
    {
      content: "Collaboration, Learning and Wellbeing",
      author:
        "Over the past year, no area has undergone more rapid transformation than the way we work. Employee expectations are changing, and we will need to define productivity much more broadly — inclusive of collaboration, learning, and wellbeing to drive career advancement for every worker, including frontline and knowledge workers, as well as for new graduates and those who are in the workforce today. All this needs to be done with flexibility in when, where, and how people work.",
      source: "~ Satya Nadella",
    },
  ];

  const characterText = [
    {
      id: 0,
      image: "/images/Characters/red_laptop.png",
      text: "Duplicated efforts leading to inefficient utilization of resources",
    },
    {
      id: 1,
      image: "/images/Characters/green_chat.png",
      text: "Duplicated efforts leading to inefficient utilization of resources",
    },
    {
      id: 2,
      image: "/images/Characters/blue_time.png",
      text: "Duplicated efforts leading to inefficient utilization of resources",
    },
    {
      id: 3,
      image: "/images/Characters/tasks.png",
      text: "Duplicated efforts leading to inefficient utilization of resources",
    },
  ];

  return (
    <div className={classes.screenCont}>
      <Nav hideTabs={isDesktop ? false : true} />

      <div className={classes.title_button_block}>
        <TitleButtonBlock1 />
      </div>

      {/* {displayWave && <img className={classes.wave1} src="/images/Waves/Wave1.svg" />} */}

      <div className={classes.dash}>
        {!isDash ? (
          <div className={classes.mobDash}>
            <img src="/dashboard/DashboardMobile.png" />
          </div>
        ) : (
          <Home />
        )}
      </div>

      {/* {displayWave && <img className={classes.wave2} src="/images/Waves/Wave2.svg" />} */}

      <div className={classes.Character_Text_Grid}>
        {isDesktop ? (
          <CharacterTextGrid />
        ) : (
          <div className={classes.text_carousel}>
            <h3 className={classes.mobileHeading1_h}>
              {" "}
              “We are tired of <span class={classes.bold}>
                inefficient{" "}
              </span>& <span class={classes.bold}>time-consuming </span>{" "}
              collaboration”{" "}
            </h3>
            <ImageTextCarousel slides={characterText} />
          </div>
        )}
      </div>

      {/* {displayWave && <img className={classes.wave3} src="/images/Waves/Wave3.svg" /> } */}

      <div className={classes.feature_scroll_cards}>
        <TitleButtonBlock2 />
        <FeatureScrollCard />
      </div>

      <div className={classes.ImageGrid}>
        <ImageGrid />
      </div>

      <img className={classes.shape1} src="/icons/shape1.svg" />
      <img className={classes.shape2} src="/icons/shape2.svg" />
      <img className={classes.shape3} src="/icons/shape3.svg" />

      {/* {displayWave && <img className={classes.wave4} src="/images/Waves/Wave4.svg" />} */}

      <div className={classes.color_cards_block}>
        <TitleButtonBlock4 />
        <ColorCardsBlock />
      </div>

      {/* <div className={classes.Testimonial_Carousel}>
                <TestimonialCarousel />
            </div> */}

      <div className={classes.text_carousel}>
        <TextCarousel slides={carouselSlidesData} />
      </div>

      <div
        className={classes.heading1}
        style={{ marginTop: isMobile ? "10vh" : "35vh" }}
      >
        <h2
          className={classes.heading1_h}
          style={{ width: isMobile ? "100%" : "90%", textAlign: "center" }}
        >
          {" "}
          How can we <span class={classes.bold}>help you&nbsp;</span>?
        </h2>
        <div className={classes.searchContainer}>
          <img src="/icons/search.svg" className={classes.searchIcon} />
          <input
            placeholder="Ask a question..."
            value={search}
            onChange={(e) => setsearchText(e.target.value)}
            className={classes.seachBox}
          />
          <button className={classes.searchBtn}>Search</button>
        </div>
      </div>
      <Faq searchText={search} />
    </div>
  );
}

export default Main_Landing_Screen;
