import classes from "./Row5.module.css"


function Row5 () {
    return (
        <div className={classes.container}>
            <div className={classes.row5}>
                <div className={classes.textbox}>
                <h1 className={classes.title}> Innovation to <span className={classes.gradient_text}> Collaboration</span>  </h1>
                <p className={classes.caption}>Enhancing and enriching the lives of people around the world by empowering them to share their knowledge and collaborate.</p>
                </div>
            </div>
        </div>
    )
}


export default Row5;