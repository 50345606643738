import classes from "./Title_Button_Block.module.css"




function Title_Button_Block4 () {
   
    return (
       
        <div className={classes.heading4}>

            <h2 className={classes.heading2_h}>We set the standard for  < span class={classes.bold}>open communication </span></h2>
            <p className={classes.heading2_p}>Unlike usual corporate communication tools (Email, MS Teams or Slack), Bditto's approaches to take advantage of open communication. We believes that everybody in a company has valuable inputs, which just needs to be unified to unfold endless opportunities .</p>
        </div> 
    )
}


export default Title_Button_Block4;