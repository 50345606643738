import React from "react";
import Nav from "../Nav/Nav";
import classes from "./User_Agreement.module.css";

const UserAgreement = () => {
  return (
    <div>
      <Nav />
      <div className={classes.container}>
        <div className={classes.header}>
          <h2 className={classes.heading}>
            User <span className={classes.bold}>Agreement</span>
          </h2>
          <p className={classes.caption}>
            Last updated and effective: March 15, 2023
          </p>
        </div>

        <div>
          <div className={classes.para}>
            <h3 className={classes.paraTitle}></h3>
            <p className={classes.paraContent}>
              This Bditto User Agreement (“Terms”) applies to your access to and
              use of the website, widgets, APIs, emails, and other online
              products and services (collectively, the “Services”) provided by
              Bditto, which is part of Bditto Limited UK company (“Bditto,”
              “we,” “us,” or “our”).
              <br />
              Remember Bditto is for fun and is intended to be a place for your
              entertainment or getting things done together, but we still need
              some basic rules. By accessing or using our Services, you agree to
              be bound by these Terms. If you do not agree to these Terms, you
              may not access or use our Services. Please take a look at Bditto’s
              Privacy Policy too—it explains how and why we collect, use, and
              share information about you when you access or use our Services.
            </p>
          </div>

          <div className={classes.para}>
            <h3 className={classes.paraTitle}>
              1. Your access to the services
            </h3>
            <p className={classes.paraContent}>
              Children under the age of 13 are not allowed to create an Account
              or otherwise use the Services. Additionally, you must be over the
              age required by the laws of your country to create an account or
              otherwise use the Services, or we need to have received verifiable
              consent from your parent or legal guardian. In addition, certain
              of our Services or portions of our Services require you to be 18
              years of age or older, so please read all notices and any
              Additional Terms carefully when you access the Services.
              <br />
              If you are accepting these Terms on behalf of another legal
              entity, including a business or government entity, you represent
              that you have full legal authority to bind such entity to these
              Terms.
            </p>
          </div>

          <div className={classes.para}>
            <h3 className={classes.paraTitle}>2. Your use of the services</h3>
            <p className={classes.paraContent}>
              Bditto grants you a personal, non-transferable, non-exclusive,
              revocable, limited license to use and access the Services solely
              as permitted by these Terms. We reserve all rights not expressly
              granted to you by these Terms. Except as permitted through the
              Services or as otherwise permitted by us in writing, your license
              does not include the right to: license, sell, transfer, assign,
              distribute, host, or otherwise commercially exploit the Services
              or Content; modify, prepare derivative works of, disassemble,
              decompile, or reverse engineer any part of the Services or
              Content; or access the Services or Content in order to build a
              similar or competitive website, product, or service We reserve the
              right to modify, suspend, or discontinue the Services (in whole or
              in part) at any time, with or without notice to you. Any future
              release, update, or other addition to functionality of the
              Services will be subject to these Terms, which may be updated from
              time to time. You agree that we will not be liable to you or to
              any third party for any modification, suspension, or
              discontinuation of the Services or any part thereof.
            </p>
          </div>

          <div className={classes.para}>
            <h3 className={classes.paraTitle}>3. Your account & security</h3>
            <p className={classes.paraContent}>
              To use our Services, you may be required to create a Bditto
              account (an “Account”) and provide us with a username, password,
              and certain other information about yourself as set forth in the
              Privacy Policy.
              <br />
              <br />
              You are solely responsible for the information associated with
              your Account and anything that happens related to your Account.
              <br />
              <br /> You must maintain the security of your Account and
              immediately notify Bditto (admin@bditto.com) if you discover or
              suspect that someone has accessed your Account without your
              permission. We recommend that you use a strong password that is
              used only with your Account. You will not license, sell, or
              transfer your Account without our prior written approval.
            </p>
          </div>

          <div className={classes.para}>
            <h3 className={classes.paraTitle}>4. Your Content</h3>
            <p className={classes.paraContent}>
              The Services may contain information, text, links, graphics,
              photos, videos, or other materials (“Content”), including Content
              created or submitted to the Services by you or through your
              Account (“Your Content”). We take no responsibility for and we do
              not expressly or implicitly endorse, support, or guarantee the
              completeness, truthfulness, accuracy, or reliability of any of
              Your Content.
              <br />
              <br />
              By submitting Your Content to the Services, you represent and
              warrant that you have all rights, power, and authority necessary
              to grant the rights to Your Content contained within these Terms.
              Because you alone are responsible for Your Content, you may expose
              yourself to liability if you post or share Content without all
              necessary rights.
              <br />
              <br />
              You retain any ownership rights you have in Your Content, but you
              grant Bditto the following license to use that Content:
              <br />
              <br />
              When Your Content is created with or submitted to the Services,
              you grant us a worldwide, royalty-free, perpetual, irrevocable,
              non-exclusive, transferable, and sublicensable license to use,
              copy, modify, adapt, prepare derivative works of, distribute,
              store, perform, and display Your Content and any name, username,
              voice, or likeness provided in connection with Your Content in all
              media formats and channels now known or later developed anywhere
              in the world. This license includes the right for us to make Your
              Content available for syndication, broadcast, distribution, or
              publication by other companies, organizations, or individuals who
              partner with Bditto. You also agree that we may remove metadata
              associated with Your Content, and you irrevocably waive any claims
              and assertions of moral rights or attribution with respect to Your
              Content.
              <br />
              <br />
              Any ideas, suggestions, and feedback about Bditto or our Services
              that you provide to us are entirely voluntary, and you agree that
              Bditto may use such ideas, suggestions, and feedback without
              compensation or obligation to you.
              <br />
              <br />
              Although we have no obligation to screen, edit, or monitor Your
              Content, we may, in our sole discretion, delete or remove Your
              Content at any time and for any reason, including for violating
              these Terms, violating our Content Policy, or if you otherwise
              create or are likely to create liability for us.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAgreement;
