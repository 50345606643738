import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails, withStyles, IconButton } from "@material-ui/core";
import styles from "./Faq.module.css";
import faqs from "./faqContent";

const StylesHead = withStyles({
  root: {
    background: ' var(--backgroud-color)',
    borderRadius: "6px",
    borderTop: "none",
    borderBottom: "none",
    border: "1px solid var(--backgroud-color)",
  },
  "&.MuiAccordion-root:before": {
    background: ' var(--backgroud-color)',
  },
})(AccordionSummary);

export default function Faq({searchText}) {
  let history = useHistory();
  const [active, setActive] = useState(0);
  const [expandedPanel, setExpandedPanel] = useState(false);
  const [filteredData,setFilteredData] = useState(faqs[active]);

  useEffect(() => {
    if (searchText?.length > 0) {
      setFilteredData(
        faqs[active].filter(
          ( faq ) => faq.head.toLowerCase().includes(searchText.toLowerCase()) 
        )
      );
    } else {
      setFilteredData(faqs[active]);
    }
  }, [searchText,active]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  return (
    <div className={styles.faqs}>
    <div className={styles.leftFaq}>
        <ul className={styles.sidebar}>
            <div className={styles.links} onClick={() => setActive(0)} >
                <li className={active === 0 ? styles.selected : styles.link}>
                General
                </li>
            </div>
            <div className={styles.links} onClick={() => setActive(1)} >
                <li className={active === 1 ? styles.selected : styles.link}>
                Subscription&nbsp;Plans
                </li>
            </div>

            <div className={styles.links} onClick={() => setActive(2)} >
                <li className={active === 2 ? styles.selected : styles.link}>
                  Education
                </li>
            </div>
            <div className={styles.links} onClick={() => setActive(3)} >
                <li className={active === 3 ? styles.selected : styles.link}>
                Privacy
                </li>
            </div>
          </ul>
    </div>
    <div className={styles.rightFaq}>
       
    
      <div className={styles.rightFaqWrapper}>
        {filteredData.map((faq, index) => (
          <Accordion
            expanded={expandedPanel === index}
            onChange={handleAccordionChange(index)}
            className={styles.boxes}
          >
            <StylesHead className={styles.boxHead}>
              <div className={styles.popoverHead}>
                <div>{faq.head}</div>
                <div>
                  <button className={styles.popoverBtn}>
                    {expandedPanel !== index ? (
                     <img src="/icons/DownChevron.svg" /> 
                    ) : (
                      <img src="/icons/UpCheveron.svg" />
                    )}
                  </button>
                </div>
              </div>
            </StylesHead>
            <AccordionDetails className={styles.boxbody}>
              <div>{faq.content}</div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
</div>
  );
}
