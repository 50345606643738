import classes from "./Feature_Card.module.css"
import "./React_Sleek.css"




function Feature_Card ({border_color, rotate, title, content}) {

    let border_class;

   if(border_color === 'red')
        border_class = classes.red_border;
    else if(border_color === 'blue')
        border_class = classes.blue_border;
    else if(border_color === 'green')
        border_class = classes.green_border;
    else if(border_color === 'yellow')
        border_class = classes.yellow_border;
    

    let rotate_class = null;
    if(rotate === 'left')
        rotate_class = classes.rotate_left;
    else if(rotate == 'right')
        rotate_class = classes.rotate_right;    
   
    return (
       
        <div  className={`${classes.feature_card} ${border_class} ${rotate_class}`}>
            <div className={classes.username}>
                @Bditto
            </div>
            <div className={classes.hashtag}>
                {title}
            </div>
            <div className={classes.content}>
                {content}
            </div>
            <div className={classes.info_box}>
                <div>
                    <div className={classes.info}>
                        <img className={classes.icon} src="/icons/users.svg"/>
                        <p className={classes.info_text}>+15 users</p>
                    </div>
                </div>
                <div className={classes.info}>
                    <div className={classes.info_element}>
                        <img className={classes.icon} src="/icons/Heart.svg"/>
                        <p className={classes.info_text}>99</p>
                    </div>
                    <div className={classes.info_element}>
                        <img className={classes.icon} src="/icons/comment.svg"/>
                        <p className={classes.info_text}>12</p>
                    </div>
                    <div className = {classes.info_element}>
                        <img className={classes.icon} src="/icons/clock.svg"/>
                        <p className={classes.info_text}>3h</p>
                    </div>
                </div>
            </div>
        </div> 
    )
}


export default Feature_Card;