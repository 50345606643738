import React from 'react';
import styles from './Mobilenav.module.css'
import { Link, useHistory, useLocation } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';

const MobileNav = ({ dash, popover, setPopover}) => {
  const history = useHistory();
  const clickHandler = () => {
    setPopover(!popover)
  }

  return (
    <>
      <div className={dash ? styles.dashbar : styles.navbar}>
        <Link to="/" className={styles.btns}>
          <img width="22px" height="22px" src="/icons/nav/home.svg" alt="thoughts" />
          <p className={styles.txt}>Home</p>
        </Link>
        <Link to="/get-in-touch" className={styles.btns} variant="contained" >
          <div className={styles.circle} id="Add_Status_mobile">
            <img height="32px" width="32px" src="/icons/nav/touch.svg" alt="tags" className={styles.plus} />
          </div><p className={styles.txt1}>Get in Touch</p>
        </Link>
        <div onClick={clickHandler} className={styles.btns} id="newsfeed_mobile">
          {
            popover ? <CloseIcon style={{ fontSize: "1.5rem" }}/> : <img height="22px" width="22px" src={"/icons/nav/more.svg"} alt="tags" />
          }
          <p className={styles.txt}>More</p>
        </div>
      </div>
    </>
  );
}

export default MobileNav;
