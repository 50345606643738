import React from "react";
import Nav from "../Nav/Nav";
import classes from "./Content_Policy.module.css";

const ContentPolicy = () => {
  return (
    <div>
      <Nav />
      <div className={classes.container}>
        <div className={classes.header}>
          <h2 className={classes.heading}>
            <span className={classes.bold}>Content</span> Policy
          </h2>
          <p className={classes.caption}>
            Last updated and effective: March 15, 2023
          </p>
        </div>

        <div>
          <div className={classes.para}>
            <h3 className={classes.paraTitle}></h3>
            <p className={classes.paraContent}>
              We created Bditto (A Bditto Limited UK company) to help people
              come together to make things happen. We expect users will have a
              great experience meeting with millions of diverse communities and
              connect with old friends and new. We can't wait to see what's
              next. <br />
              <br />
              Our guidelines are meant to explain what is and isn’t allowed on
              Bditto and ensure that everyone has a good experience. If you come
              across a message that appears to break these rules, please report
              it to us. User communities should create a sense of belonging for
              their members, not try to diminish it for others. Likewise,
              everyone on Bditto should have an expectation of privacy and
              safety, so please respect the privacy and safety of others.We may
              take a number of steps, including issuing a warning, removing the
              content, or removing the accounts responsible. <br />
              <br />
              Bditto and its communities are only what we make of them together
              and can only exist if we operate by a shared set of rules. We ask
              that you abide by not just the letter of these rules, but the
              spirit as well. <br />
              <br />
              We want to be clear about the expectations for our users. Every
              user of Bditto should feel like their voice can be heard, but not
              at the expense of someone else. <br />
              <br />
              This Bditto User Agreement (“Terms”) applies to your access to and
              use of the website, widgets, APIs, emails, and other online
              products and services (collectively, the “Services”) provided by
              Bditto, which is part of Bditto Limited UK company (“Bditto,”
              “we,” “us,” or “our”). <br />
              <br />
              Remember Bditto is for fun and is intended to be a place for your
              entertainment or getting things done together, but we still need
              some basic rules. By accessing or using our Services, you agree to
              be bound by these Terms. If you do not agree to these Terms, you
              may not access or use our Services. <br />
              <br />
              Please take a look at Bditto’s Privacy Policy too—it explains how
              and why we collect, use, and share information about you when you
              access or use our Services.
            </p>
          </div>

          <div className={classes.para}>
            <h3 className={classes.paraTitle}>
              Here are some rules for interacting with others:{" "}
            </h3>
            <p className={classes.paraContent}>
              {" "}
              1. Do not organize, participate in, or encourage harassment of
              others. Disagreements happen and are normal, but continuous,
              repetitive, or severe negative comments may cross the line into
              harassment and are not okay. <br />
              <br />
              2. Do not organize, promote, or coordinate groups around hate
              speech. It’s unacceptable to attack a person or a community based
              on attributes such as their race, ethnicity, national origin, sex,
              gender, sexual orientation, religious affiliation, or
              disabilities.
              <br />
              <br />
              3. Do not make threats of violence or threaten to harm others.
              This includes indirect threats, as well as sharing or threatening
              to share someone’s private personal information (also known as
              doxxing).
              <br />
              <br />
              4. Do not evade user blocks. Do not send unwanted, repeated friend
              requests or messages, especially after they’ve made it clear they
              don’t want to talk to you anymore.
              <br />
              <br />
              5. Do not try to hide your identity in an attempt to contact
              someone who has blocked you, or otherwise circumvent the tools we
              have which enable users to protect themselves.
              <br />
              <br />
              6. Do not send others viruses or malware, attempt to phish others,
              or hack or DDoS them.
            </p>
          </div>

          <div className={classes.para}>
            <h3 className={classes.paraTitle}>
              Here are some rules for content on Bditto :{" "}
            </h3>
            <p className={classes.paraContent}>
              1. You must apply the #NSFW label to statuses if there is adult
              content in that channel. You may not share or post any sexually
              explicit or pornographic content.
              <br />
              <br />
              2. You may not sexualize minors in any way. This includes sharing
              content or links which depict minors in a pornographic, sexually
              suggestive, or violent manner, and includes illustrated or
              digitally altered pornography that depicts minors (such as
              lolicon, shotacon, or cub). We report illegal content to the
              National Center for Missing and Exploited Children.
              <br />
              <br />
              3. You may not share sexually explicit content of other people
              without their consent, or share or promote sharing of
              non-consensual intimate imagery (also known as revenge porn) in an
              attempt to shame or degrade someone.
              <br />
              <br />
              4. You may not share content that glorifies or promotes suicide or
              self-harm, including any encouragement to others to cut
              themselves, or embrace eating disorders such as anorexia or
              bulimia.
              <br />
              <br />
              5. You may not share images of sadistic gore or animal cruelty.
              <br />
              <br />
              6. You may not use Bditto for the organization, promotion, or
              support of violent extremism.
              <br />
              <br />
              7. You may not operate or engage in any discussion that sells or
              facilitates the sales of prohibited or potentially dangerous
              goods.This includes firearms, ammunition, drugs, and controlled
              substances.
              <br />
              <br />
              8. You may not promote, distribute, or provide access to content
              involving the hacking, cracking, or distribution of pirated
              software or stolen accounts.This includes sharing or selling
              cheats or hacks that may negatively affect others in multiplayer
              games.
              <br />
              <br />
              9. In general,you should not promote, encourage or engage in any
              illegal behavior.This is very likely to get you kicked off Bditto,
              and may get you reported to law enforcement.
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentPolicy;
