import classes from "./Member.module.css";

function Member({ name, role, imageURL }) {
  return (
    <div className={`${classes.image_slide} `}>
      <div
        className={`${classes.image}`}
        style={{ backgroundImage: `url(${imageURL})` }}
      ></div>

      <div className={`${classes.content}`}>
        <h2 className={`${classes.name} `}>{name}</h2>
        <p className={`${classes.quote}`}>{role}</p>
      </div>
    </div>
  );
}

export default Member;
