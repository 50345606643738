import React, { useState } from "react";
import classes from "./Testimonial_Caraousel.module.css";
import ImageSlide from "./Image_Slide/Image_Slide";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from "react-responsive";




function Testimonial_Carousel () {

  const isDesktop = useMediaQuery({ query: "(min-device-width: 768px)" });
  const carouselSlidesData = [
    {
      imageURL:"/images/Testimonial/testimonial2_image.png" ,
      name:"Shivani Malik",
      quote:"“Easy to use collaborative platform that helps people or teams to share knowledge.”"
    },{
        imageURL:"/images/Testimonial/testimonial1_image.png",
        name:"Divya Dutt",
        quote:"“I was able to connect with people & co-founders on Bditto for my recent startup ”" 
    },
    {
      imageURL:"/images/Testimonial/testimonial3_image.png",
      name:"Ajay Rana",
      quote:"“I was able to connect with people & co-founders on Bditto for my recent startup ”"
    },
    {
      imageURL:"/images/Testimonial/testimonial1_image.png",
        name:"Divya Dutt",
        quote:"“I was able to connect with people & co-founders on Bditto for my recent startup ”" 
    }
  ];

  const NextArrow = ({onClick, className}) => {
    return (
      <div className={`${className}`} onClick={onClick}>
              <img 
                src="/Arrow_Right.png"
              />
      </div>
    )
  }

  const PrevArrow = ({onClick, className}) => {
    return (
      <div className={`${className}`} onClick={onClick}>
              <img 
                src="/Arrow_Left.png" 
              
              />
      </div>
    )
  }


  const [slideIndex, setSlideIndex] = useState(0);
 
  const settings = {
    dots: false,
    arrows: isDesktop,
    infinite: true,
    speed: 1000,
    slidesToShow: isDesktop ?  3 : 1.1,
    centerMode: true,
    slidesToScroll: 1,
    centerPadding: 0,
    centerMode: true,
    beforeChange: (current, next) => setSlideIndex(next)
};

    return (
        <React.Fragment>
              
            {/* <div className ={`${classes.Testimonial_Carousel} testimonial_carousel`}> */}
                
                <Slider {...settings}>
                  
                  {
                       carouselSlidesData.map((data, index) => (
                        <ImageSlide 
                            imageURL = {data.imageURL}
                            name = {data.name}
                            quote = {data.quote}
                            blur={ index === slideIndex  ? false : index === (slideIndex + 2)%carouselSlidesData.length ? false : true} 
                        />))
                  } 

                </Slider>
          {/* </div>  */}

          
          {/* <div className={classes.ScrollButtons}>
            <img 
              src="/Arrow_Left.png"
              className={classes.left_arrow}
              onClick={() => executeScroll(-1)}
            />

            <img 
              src="/Arrow_Right.png" 
              className={classes.right_arrow}
              onClick={() => executeScroll(1)}
             />
           */}
       
        
        </React.Fragment>
       
    )
}


export default Testimonial_Carousel;