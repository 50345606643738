import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./Suggestions.module.css";
import { FormattedMessage } from "react-intl";
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import StatusCard from "../../Extras/StatusCard/StatusCard";
import { useMediaQuery } from "react-responsive";

const Suggestions =(props)=> {     

  const isTab =  useMediaQuery({  maxWidth: 1299 });
  const isSmallTab =  useMediaQuery({  maxWidth: 1028 });

  const feedData = [
    {
      id: 1,
      name: "nikole_willis",
      color: "blue",
      avatar: "/dashboard/user1.svg",
      width: isTab ? isSmallTab ? "39.5%" : "40%" : "42%",
      content: "Need help with Excel Vlookup formula",
    },
    {
      id: 2,
      name: "larry_bowman",
      color: "red",
      avatar: "/dashboard/user2.svg",
      width: isTab ? isSmallTab ? "39.5%" : "40%" : "42%",
      content: "Check out our new partnership benefit program",
    },
    {
      id: 3,
      name: "caroline",
      color: "green",
      avatar: "/dashboard/user3.svg",
      width: isTab ? isSmallTab ? "39.5%" : "40%" : "42%",
      content: "Where can I find sales pitch presentations",
    },
    {
      id: 4,
      name: "zoe",
      color: "yellow",
      avatar: "/dashboard/user4.svg",
      width: isTab ? isSmallTab ? "39.5%" : "40%" : "42%",
      content: "Who has a list of all company cell phone numbers",
    },
    {
      id: 5,
      name: "charles_ortega",
      color: "red",
      avatar: "/dashboard/user5.svg",
      width: isTab ? isSmallTab ? "39.5%" : "40%" : "42%",
      content: "How to set up fixed assets in our accounting tool",
    },
    {
      id: 6,
      name: "adriana_black",
      color: "blue",
      avatar: "/dashboard/user6.svg",
      width: isTab ? isSmallTab ? "39.5%" : "40%" : "42%",
      content: "Can I create a Purchase Order from home",
    },
    {
      id: 7,
      name: "jacob_porter",
      color: "blue",
      avatar: "/dashboard/user6.svg",
      width: isTab ? isSmallTab ? "39.5%" : "40%" : "42%",
      content: "Is it possible to carryover vacation days to the next year",
    },
    {
      id: 8,
      name: "priyanka_gupta",
      color: "blue",
      avatar: "/dashboard/user6.svg",
      width: isTab ? isSmallTab ? "39.5%" : "40%" : "42%",
      content: "When is the weekly vendor payment run",
    },
  ]

  const data = [
    {
      id: 1,
      name: "nikole_willis",
      color: "blue",
      avatar: "/dashboard/user1.svg",
      width: isTab ? isSmallTab ? "39.5%" : "40%" : "42%",
      content: "Company released a new policy on Work from home",
    },
    {
      id: 2,
      name: "larry_bowman",
      color: "red",
      avatar: "/dashboard/user2.svg",
      width: isTab ? isSmallTab ? "39.5%" : "40%" : "42%",
      content: "Need help with new WFH regulations",
    },
    {
      id: 3,
      name:  "zoe",
      color: "green",
      avatar: "/dashboard/user3.svg",
      width: isTab ? isSmallTab ? "39.5%" : "40%" : "42%",
      content: "Check out the company`s new work from home policy",
    },
    {
      id: 4,
      name:"caroline",
      color: "yellow",
      avatar: "/dashboard/user4.svg",
      width: isTab ? isSmallTab ? "39.5%" : "40%" : "42%",
      content: "Have doubts on insurance coverage for the new WFH policy",
    },
    {
      id: 5,
      name: "charles_ortega",
      color: "red",
      avatar: "/dashboard/user5.svg",
      width: isTab ? isSmallTab ? "39.5%" : "40%" : "42%",
      content: "Do we need a written approval for work from home",
    },
    {
      id: 6,
      name: "adriana_black",
      color: "blue",
      avatar: "/dashboard/user6.svg",
      width: isTab ? isSmallTab ? "39.5%" : "40%" : "42%",
      content: "Can I work from home anywhere in the globe ?",
    },
  ]

  const displayActiveSuggestion=()=> {
    return (
        <>
         {props.dash == "myfeed"? 
              <>
                {
                  feedData.map((i) => <StatusCard border_color={i.color} avatar={i.avatar} name={i.name} width={i.width} content={i.content}/>)
                }
              </>
                :
              <>
                {
                  data.map((i) => <StatusCard border_color={i.color} avatar={i.avatar} width={i.width} content={i.content} name={i.name}/>)
                }
              </>
          } </> );
            }

  
    return (
      <div className={styles.mainContainer} id="Suggestions">
    {props.dash == "myfeed"?
    <div className={styles.bredcrumb}>
            <h3 className={styles.selectBread} >
            <img src="/dashboard/emoji.svg" style={{marginBottom:'-0.3rem'}} />&nbsp;My Feed
            </h3>
          </div>
    :
    <div className={styles.bredcrumb}>
            <h3 className={styles.selectBread} >
            <img src="/dashboard/arrowDown.svg" style={{marginBottom:'-0.3rem'}} />&nbsp;Matches
              &nbsp;
            </h3>
            <h3 className={styles.notSelected}> &nbsp;&nbsp;
            <img src="/dashboard/inactive.svg" style={{marginBottom:'-0.3rem'}} />&nbsp;Inactive
            </h3>
          </div>
      }
        <div className={styles.statusDisplay}>
            {displayActiveSuggestion()}
        </div>
      </div>
    );
  }

export default Suggestions;
