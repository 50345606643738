import React, { Component, createRef } from "react";
import Button from "@material-ui/core/Button";
import styles from "./Sidebar.module.css";
import { FormattedMessage } from "react-intl";
import StatusCard from "../../Extras/StatusCard/StatusCard";
import SmallStatusCard from "../../Extras/SmallCard/SmallStatusCard";
import { useMediaQuery } from "react-responsive";

const activeData = [
  {
    content: "Restrictions to meet a customer during Covid 19",
  },
  {
    content: "Any good restaurant recommendations for business Lunch in London",
  }
];

const deactivatedData = [
  {
    content: "I am drafting a sales policy and would like to discuss some key strategic points",
  },
  {
    content: "Who is in charge of our companies LinkedIn Account",
  },
  {
    content: "New to the company, how to book a meeting room",
  }
];

const Sidebar = (props) => {
    const addStatus = {
      background: "var(--button-gradient1)",
      borderRadius: "98px",
      opacity: "1",
      fontSize: "15px",
      color: "var(--heading-color1)",
      textTransform: "none",
      height: "46px",
      outline: "none !important",
      marginBottom: "1.5rem",
      width: "92%",
      fontStyle: "normal",
      lineHeight: "19.9px",
      fontWeight: "400",
    }
    const isTab =  useMediaQuery({maxWidth: 1299 });
    const isSmallTab =  useMediaQuery({maxWidth: 1028 });
    
    const isMobile = window.screen.width < 600;
    return (
      <>
        <div className={styles.addContainer}>
          <div id="Add_Status" className={styles.addStatusContainer}>           
            <div className={styles.searchBox}>
              <img src="/icons/search.svg" />
              <input
                type="text"
                className={styles.searchField}
                disabled
                placeholder="Search your Thoughts"
              />
            </div>

            <Button variant="contained" style={addStatus}>             
              <FormattedMessage id="addThoughts" defaultMessage={`Add Thoughts`} />
              <img src="/dashboard/plus.svg" style={{marginLeft:'0.5rem'}} />
            </Button>
          </div>
          
          <span className={styles.thoughts}>
            <img src="/dashboard/docs.svg" style={{marginLeft:'0.5rem'}} />
            <h3 className={styles.thoughtsHead}>My Thoughts</h3>
          </span>

          <div  className={styles.statusList}>
            <StatusCard border_color="sidebar" width="90" />

            {
              activeData.map((item) => <SmallStatusCard border_color="red" width="90" item={item.content}/>)
            }
            
            <span className={styles.thoughts}> 
              <img src="/dashboard/ban.svg" style={{marginLeft:'0.5rem'}} />
              <h3 className={styles.thoughtsHead}>Deactivated</h3>
            </span>
            
            {
              deactivatedData.map((item) => <SmallStatusCard border_color="red" width="90" item={item.content}/>)
            }
          </div>
        </div>
      </>
    );
  }


export default Sidebar;
