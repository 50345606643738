import React from 'react'
import styles from "./StatusCard.module.css"
import { useMediaQuery } from "react-responsive";
import { Avatar } from '@material-ui/core';

const StatusCard = ({border_color, width, avatar, content, name}) => {
    const isTab =  useMediaQuery({ maxWidth : 1299 });
    const isSmallTab =  useMediaQuery({ maxWidth : 1018 });
    let border_class;

    if(border_color === 'red')
         border_class = styles.red_border;
     else if(border_color === 'blue')
         border_class = styles.blue_border;
     else if(border_color === 'green')
         border_class = styles.green_border;
     else if(border_color === 'yellow')
         border_class = styles.yellow_border;
     else if(border_color === 'sidebar')
         border_class = styles.sideborder;
     
    return (
        <div  className={`${styles.feature_card} ${border_class}`} style={{width:width }}>
            <div className={styles.username}>
                {
                    avatar ? <img src={avatar} /> : <Avatar style={{width:'2rem' , height:'2rem', background: "#C2185B", fontSize: "1rem"}}>G</Avatar>
                }
                &nbsp;&nbsp;@{name ? name : "Grace"}
                <img src="/dashboard/options.svg" style={{marginLeft:'auto'}} />
            </div>
            <div className={styles.content}>{content ? content : "Exploring new work from home policy"}</div>
            <div style={{width:'100%'}}>
                {border_color === 'sidebar' ? 
            <img src="/dashboard/img.svg" style={{marginBottom:'0.2rem', width:'46px',height:'24px', marginTop:'-0.9rem'}} />:
            <img src="/dashboard/img.svg" style={{marginBottom:'0.5rem'}} />
            }
            </div>
            <div className={styles.info_box}>
                <div>
                    <div>
                    {border_color != 'sidebar' &&  <img src="/dashboard/usersSmall.svg" style={{marginBottom:'-0.15rem'}}  />}  +15 users
                     </div>
                </div>
                <div className={styles.info}>
                    <div className={styles.info_element} style={{marginRight: border_color === 'sidebar'&& isSmallTab? "0.25rem": "" }}>
                    <img src="/dashboard/love.svg" style={{marginTop:'-0.12rem'}} />&nbsp;99
                    </div>
                    <div className={styles.info_element} style={{marginRight: border_color === 'sidebar'&& isSmallTab? "0.25rem": "" }}>
                    <img src="/dashboard/msg.svg" style={{marginTop:'-0.1rem'}} />&nbsp;12
                    </div>
                    <div>
                    <img src="/dashboard/time.svg" style={{marginBottom:'-0.15rem'}} /> 3h
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default StatusCard
