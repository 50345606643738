import classes from "./Row2.module.css"


function Row2 () {
    return (
        <div className={classes.container}>
            <div className={classes.row}>
                <div>
                    <img className={classes.image} src="images/About_Us/Row2.svg"  />
                </div>   

                <div className={classes.textbox}>
                    <h1 className={classes.title}>The idea behind  <span className={classes.gradient_text}> Bditto</span> </h1>
                    <p className={classes.caption}>Bditto started on a small scale, just like all startups. The moment we saw an opportunity, we seized it in order to be the first to fill that need.
                        <br />
                        <br />
                         </p>
                </div>  
            </div>
        </div>
    )
}


export default Row2;