import React from 'react'
import styles from "./StatusCard.module.css"
import Avatar from "@material-ui/core/Avatar";
const ExploreStatusCard = ({border_color, width, avatar, content}) => {
    let border_class;

    if(border_color === 'red')
         border_class = styles.red_border;
     else if(border_color === 'blue')
         border_class = styles.blue_border;
     else if(border_color === 'green')
         border_class = styles.green_border;
     else if(border_color === 'yellow')
         border_class = styles.yellow_border;
     else if(border_color === 'sidebar')
         border_class = styles.sideborder;
     
    return (
        <div  className={`${styles.feature_card} ${border_class}`} style={{width:width, marginTop:'0em' }}>
            <div className={styles.username}>
            <img src={avatar} /> &nbsp;  @raghavmisra
              <img src="/dashboard/options.svg" style={{marginLeft:'auto'}} />
            </div>
            <div className={styles.content} style={{marginTop:'0.1rem'}}>
                {content ? content : " We need to finish task asap. Mrinal, the required files are attached..."}
            </div>
            <div style={{width:'90%'}}>
            <img src="/dashboard/img.svg" style={{marginBottom:'0.6rem'}} />
           
            </div>
            <div className={styles.info_box}>
                <div>
                    <div>
                    <img src="/dashboard/usersSmall.svg" style={{marginBottom:'-0.18rem', display:'inline'}}  />   +15 users
                    </div>
                </div>
                <div className={styles.info}>
                    <div className={styles.info_element}>
                    <img src="/dashboard/love.svg" style={{marginTop:'-0.15rem'}} />&nbsp;99
                    </div>
                    <div className={styles.info_element}>
                    <img src="/dashboard/msg.svg" style={{marginTop:'-0.15rem'}} />&nbsp;12
                    </div>
                    <div className={styles.info_element}>
                    <img src="/dashboard/time.svg" style={{marginTop:'-0.15rem'}} />&nbsp;12h
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default ExploreStatusCard
