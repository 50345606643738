import React, { useState } from "react";
import classes from "./Team.module.css";
import Member from "./Member/Member";

function Team() {
  const carouselSlidesData = [
    [
      {
        name: "Siva Dantuluri",
        role: "Founder and CEO",
        imageURL: "/images/About_Us/Team/SivaDantuluri.jpg",
      },
      {
        name: "Yadu Krishna Valluru",
        role: "Director",
        imageURL: "/images/About_Us/Team/Krishna.jpeg",
      },
      {
        name: "Abhinav Verma",
        role: "Full Stack Developer",
        imageURL: "/images/About_Us/Team/AbhinavVerma.jpg",
      },
      {
        name: "Prasanth Battula",
        role: "Full Stack Developer",
        imageURL: "/images/About_Us/Team/prasanth.png",
      },
    ],
    [
      // {
      //   name: "Prasanth Battula",
      //   role: "Full Stack Developer",
      //   imageURL: "/images/About_Us/Team/prasanth.png",
      // },
      // {
      //   name: "Abhinav Verma",
      //   role: "Full Stack Developer",
      //   imageURL: "/images/About_Us/Team/AbhinavVerma.jpg",
      // },
    ],
    [],
  ];

  const [currDept, setCurrDept] = useState(0);

  return (
    <div className={`${classes.container} `}>
      {/* <div className={classes.departments_container}>
        <div className={classes.select_departments}>
          <div
            className={
              currDept === 0 ? classes.department_select : classes.department
            }
            onClick={() => setCurrDept(0)}
          >
            <p>Founding&nbsp;Team</p>
          </div>
          <div
            className={
              currDept === 1 ? classes.department_select : classes.department
            }
            onClick={() => setCurrDept(1)}
          >
            <p>Product&nbsp;Development</p>
          </div>
          
          <div
            className={
              currDept === 2 ? classes.department_select : classes.department
            }
            onClick={() => setCurrDept(2)}
          >
            <p>Research&nbsp;&&nbsp;Operations</p>
          </div>
        </div>
      </div> */}

      <div className={classes.team_members}>
        {carouselSlidesData[currDept].map((data) => {
          return (
            <Member
              name={data.name}
              role={data.role}
              imageURL={data.imageURL}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Team;
