import classes from "./Title_Button_Block.module.css"
import VioletButton from "../../Buttons/Violet_Button/Violet_Button";
import { useHistory } from "react-router-dom";



function Title_Button_Block1 () {
    const history = useHistory();
    return (
        <div className={classes.heading1}>
            <h2 className={classes.heading1_h}> Collaboration <span class={classes.bold}>Redefined</span></h2>
            <p className={classes.heading1_p}>Empowering teams to share ideas, connect & work together in real-time.</p>
            <div className={classes.heading1_button} >
                <a href="https://dashboard.bditto.com/register">
                    <VioletButton className={classes.heading1_button}>Explore Demo<span className={classes.heading1_button_arrow}>&#8594;</span></VioletButton>
                </a>
            </div>

        </div> 
    )
}


export default Title_Button_Block1;