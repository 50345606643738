import React, { Component, createRef } from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core/styles";
import styles from "./InterestSidebar.module.css";
import { Avatar, IconButton } from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";
import { FormattedMessage } from "react-intl";
import StatusCard from "../../Extras/StatusCard/StatusCard";
import SmallStatusCard from "../../Extras/SmallCard/SmallStatusCard";

class InterestSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
    };
    this.searchInputRef = createRef();
  }

 
  render() {
    const { classes } = this.props;

    const isMobile = window.screen.width < 600;
    return (
      <>
        <div className={styles.addContainer}>
          <div className={styles.addStatusContainer}>
            {this.props.dash == "explore" ? (
              <span className={styles.thoughts}> 
                <img src="/dashboard/trend.svg" style={{marginLeft:'0.5rem'}} />
                <h3 className={styles.thoughtsHead}>Trending
                </h3>
              </span>
              ) : (
              <span className={styles.thoughts}> 
                <img src="/dashboard/star.svg" style={{marginLeft:'0.5rem'}} />
                <h3 className={styles.thoughtsHead}>My Interests
                </h3>
              </span>     
              )
            }
            {this.props.dash == "/" &&           
              <div className={styles.searchBox}>
                <img src="/icons/search.svg" />
                <input
                  type="text"
                  className={styles.searchField}
                  disabled
                  placeholder="Add Interests"
                />
              </div>
            }
          </div>
           <div  className={this.props.dash == "explore" ? styles.trending : styles.statusList}>
            <div className={styles.activetagContainer}>
              <h4 className={styles.activetag}>Work from home&nbsp;<span className={styles.count}>(5)</span></h4>
              <h4 style={{ color: "var(--heading-color2)", fontSize: "1rem", fontFamily: "var(font-family)", fontStyle: "normal", fontWeight: "normal", marginBottom: "0px" }}>-</h4>
            </div>
            <div className={styles.activetagContainer}>
              <h4 className={styles.activetag}>Sales&nbsp;<span className={styles.count}>(4)</span></h4>
              <h4 style={{ color: "var(--heading-color2)", fontSize: "1rem", fontFamily: "var(font-family)", fontStyle: "normal", fontWeight: "normal", marginBottom: "0px" }}>-</h4>
            </div>
            <div className={ styles.tagContainer}>
              <h4 className={styles.tag}>Digital Marketing&nbsp;<span className={styles.count}>(15)</span></h4>
              <h4 style={{ color: "var(--secondary)", fontSize: "1rem", fontFamily: "var(font-family)", fontStyle: "normal", fontWeight: "normal", marginBottom: "0px" }}>+</h4>
            </div>
            <div className={ styles.tagContainer}>
                <h4 className={styles.tag}>Covid-19&nbsp;<span className={styles.count}>(36)</span></h4>
                <h4 style={{ color: "var(--secondary)", fontSize: "1rem", fontFamily: "var(font-family)", fontStyle: "normal", fontWeight: "normal", marginBottom: "0px" }}>+</h4>
            </div>
            <div className={ styles.tagContainer}>
                <h4 className={styles.tag}>Leadership&nbsp;<span className={styles.count}>(9)</span></h4>
                <h4 style={{ color: "var(--secondary)", fontSize: "1rem", fontFamily: "var(font-family)", fontStyle: "normal", fontWeight: "normal", marginBottom: "0px" }}>+</h4>
            </div>
            <div className={ styles.tagContainer}>
                <h4 className={styles.tag}>Excel&nbsp;<span className={styles.count}>(9)</span></h4>
                <h4 style={{ color: "var(--secondary)", fontSize: "1rem", fontFamily: "var(font-family)", fontStyle: "normal", fontWeight: "normal", marginBottom: "0px" }}>+</h4>
            </div>
              {/* <StatusCard border_color="Interestsidebar" width="90"/>
              <SmallStatusCard border_color="red" width="90"/>
              <SmallStatusCard border_color="red" width="90"/> */}
        
              {/* <SmallStatusCard border_color="red" width="90"/>
              <SmallStatusCard border_color="red" width="90"/> */}
          </div>
        </div>
      </>
    );
  }
}


export default(withRouter(InterestSidebar));
