import classes from "./Character_Text_Grid.module.css";
import React from "react";


function Character_Text_Grid () {
   
    return (
        <div className={classes.Character_Text_Grid}>
            
            <div className={`${classes.character_container}`}>
                <div className={`${classes.image}`}>  
                     <img className={classes.top_image1_row} src="/images/Characters/red_laptop.png" />
                </div>
                <div className={`${classes.image} ${classes.top_image_row}`}>
                    <img className={classes.top_image2_row} src="/images/Characters/green_chat.png" />
                </div>           
            </div>

           
            <div className={classes.character_box_container}>
                <div className={`${classes.text_box} ${classes.red_border} ${classes.top_text_row}`}>
                    Duplicated efforts leading to inefficient utilization of resources
                </div>
            
                <div className={`${classes.text_box} ${classes.green_border} ${classes.top_text_row}`}> 
                    Communication gaps and latency of information sharing delays project timelines
                </div>
            </div>

            <div class={classes.background_box_container}>
                <div class={classes.background_box}></div>
                <div class={classes.background_box}></div>
            </div>

          
                <h2 className={classes.heading1_h}> “We are tired of < span class={classes.bold}>inefficient </span>&   < span class={classes.bold}>time-consuming </span> collaboration”  </h2>
          



            <div className={classes.character_container}>
                <div className={classes.image}>  
                     <img className={classes.botttom_image1_row} src="/images/Characters/blue_time.png" />
                </div>
                <div className={classes.image}>
                    <img className={classes.bottom_image2_row} src="/images/Characters/tasks.png" />
                </div>           
            </div>

            

            <div className={classes.character_box_container}>
                <div className={`${classes.text_box} ${classes.blue_border} ${classes.bottom_text_row}`}>
                    Knowledge and time are not optimally utilized
                </div>
                <div className={`${classes.text_box} ${classes.yellow_border} ${classes.bottom_text_row}`}> 
                    Lack of central hub for enterprise`s knowledge
                </div>
            </div>
        </div>
    )
}


export default Character_Text_Grid;