import React, { PureComponent, useEffect } from 'react'
import classes from "./Nav.module.css";
import TransparentWhiteButton from "../Buttons/Transparent_white_button/Transparent_White_Button";
import VioletButton from "../Buttons/Violet_Button/Violet_Button";
import TransparentBlueButton from "../Buttons/Transparent_blue_button/Transparent_Blue_Button";
import { useHistory, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";

const Nav =(props)=> {
const history = useHistory()
const location = useLocation().pathname
const marginClass = props.No_Margin ? classes.no_margin : null;
const isMobile =  useMediaQuery({ maxWidth: 889 });
const [scrolled,setScrolled]=React.useState(false);
const handleScroll=() => {
    const offset=window.scrollY;
    if(offset > 70 ){
      setScrolled(true);
    }
    else{
      setScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll',handleScroll)
  })
let navbarClasses=null;
let navbarClasses2=null;
  if(scrolled){
    navbarClasses = classes.scrolled;
    navbarClasses2 = classes.scrolledNav;
  }
        return (
        <>
          <div className={`${classes.container} ${navbarClasses}`}>
            <div className={`${classes.nav} ${marginClass} ${navbarClasses2}`}>
              <span className={classes.logo} onClick={()=> history.push("/")}><img src="/logoMain.svg" alt="logo.png"  className={classes.navLogo}/></span>
              {(!props.hideTabs && !isMobile)  &&
                <>
                  <Link to="/" >
                    <span>Business </span>
                    {location === "/"?
                      <img style={{marginTop:'0.15rem'}} src="/dashboard/underline.svg" width="50px" height="4px"/>
                    : null}
                  </Link>
                  <Link to="/lab">
                    <span>Bditto Labs </span>

                    {location === "/lab"?
                      <img style={{marginTop:'0.15rem'}} src="/dashboard/underline.svg" width="50px" height="4px"/>
                    : null}
                  </Link>

                  <Link to="/blog">
                    <span>Blogs </span>
                    {location.slice(0,5) === "/blog" ? 
                    <img style={{marginTop:'0.15rem'}} src="/dashboard/underline.svg" width="40px" height="9px"/>
                    : null}</Link>


                    <Link to="/aboutus" >
                    <span>About Us </span>
                    {location === "/aboutus"?
                    <img style={{marginTop:'0.15rem'}} src="/dashboard/underline.svg" width="50px" height="4px"/>
                    : null}
                    </Link>


                    <Link to="/education" >
                    <span>For Education</span>


                    {location === "/education"?
                    <img style={{marginTop:'0rem'}} src="/dashboard/underline.svg" width="50px" height="4px"/>
                    : null}
                     </Link>
                    </>
                    }
                  {!isMobile ?
                    <> {!props.hideTabs &&
                      <div className={classes.button_box} >
                        <div onClick={()=>history.push("/pricing")}>
                          <TransparentWhiteButton>Pricing</TransparentWhiteButton>
                        </div>
                        <div className={classes.shift_left} onClick={()=>history.push("/get-in-touch")}>
                          <TransparentBlueButton>Get in Touch</TransparentBlueButton>
                        </div>
                      </div>
                      }
                    </> : <div className={classes.button_box} >
                      <div onClick={()=>history.push("/pricing")}>
                        <TransparentBlueButton>Pricing</TransparentBlueButton>
                      </div>
                    </div>
                  }
                </div>
            </div>
            {props.children}
        </>
    );
}


export default Nav;
