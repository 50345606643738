import React, { Component, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";

import Sidebar from "./LeftSidebar/Sidebar";
import Styles from "./Home.module.css";
import Suggestions from "./Suggestions/Suggestions";

import { FormattedMessage } from "react-intl";
import { Avatar , Button} from "@material-ui/core";
import { Add, Close, PlusOne } from "@material-ui/icons";
import Navbar from "./Navbar/Navbar";
import InterestSidebar from "./RightSidebar/InterestSidebar";
import ConnectionSidebar from "./LeftSidebar/ConnectionSidebar";
import SuggestionsSidebar from "./RightSidebar/SuggestionsSidebar";
import Explore from "./Explore/Explore";
import Chat from "./Chat/Chat";

const useStyles = (theme) => ({
  addStatus: {
    background: " linear-gradient(225deg, #FF5B5B 0%, #FC4249 100%)",
    opacity: "1",
    fontSize: "17px",
    color: "#ffffff",
    fontFamily: "Roboto",
    textTransform: "none",
    height: "45px",
    outline: "none !important",
    marginBottom: "1.5rem",
    width: "82%",
    fontStyle: "normal",
    lineHeight: "19.9px",
    fontWeight: "400",
    marginRight:'0.5rem', borderRadius: "6px",
  },
});
const Home =()=> {
 
  const[dash, setDash]= useState("/");
  const [time, setTime] = useState(5)
  const Dashvalues = ['/', 'explore', 'myfeed', 'chat' ]
  const changeDash = (value = null) =>{

    if(value)
      setDash(value);
    else{
      switch(dash) {
        case '/':
          setDash('explore');
          break;
        case 'explore':
          setDash('myfeed');
          break;
        case 'myfeed':
          setDash('chat');
          break;
        case 'chat':
          setDash('/');
          break;
      }
    }
  }
  
    const changeDashReverse = ()=>{
      switch(dash) {
        case '/':
          setDash('chat');
          break;
        case 'myfeed':
          setDash('explore');
          break;
        case 'chat':
          setDash('myfeed');
          break;
        case 'explore':
          setDash('/');
          break;
      }
    }
  
 

//  useEffect(() => {
//   const countdown = window.setInterval(() => {
//     switch(dash) {
//       case '/':
//         setDash('explore');
//         break;
//       case 'explore':
//         setDash('myfeed');
//         break;
//       case 'myfeed':
//         setDash('chat');
//         break;
//       case 'chat':
//         setDash('/');
//         break;
//     }
//   }, 5000)

//   return () => {
//     window.clearInterval(countdown)
//   }
// }, [dash])

    const { classes } = useStyles();
    const isMobile = window.screen.width < 600;
    return (
      <div>
         <div className={Styles.dashboardToggler}>
            <div className={Styles.toggler} onClick = {() => changeDashReverse()}>
              <img src = "/icons/leftArrow.svg"/>
            </div>
            <div className={Styles.toggler} onClick = {() => changeDash()}>
              <img src = "/icons/rightArrow.svg"/>
            </div>
        </div>
        <div className={Styles.wholeContainer}>
          <Navbar dash={dash} changeDash={changeDash}/>

          <div className={Styles.center}>
              <div className={ dash === "explore"? Styles.hide :Styles.sidebarContainer}>
                <div className={Styles.grpContainer}>
                  <Avatar style={{width:'1.7em' , height:'1.7em', background: "#D9DEF4", color: "#333"}}>G</Avatar>
                  <span className={Styles.grpname}>Global</span>
                  <span>
                    <img src="/dashboard/chevronDown.svg" style={{width:'1.1em' , height:'1.05em' ,marginTop:'0.4rem',}} />
                  </span> 
                </div>
                {
                  dash === "myfeed" ||  dash === "chat"?
                    <ConnectionSidebar dash={dash}/>
                  : dash === "explore"?
                  <></>
                  :<Sidebar/> 
                }
              </div>
              {dash === "explore" ?
                <Explore />
                : dash === "chat" ? 
                <Chat />
                : <Suggestions dash={dash} />
              }
              
              {dash === "myfeed"?
                <SuggestionsSidebar/>
                : dash !== "chat" && <InterestSidebar dash={dash} />
              }
          </div>
        </div>
      </div>
    );
  }

export default Home;
