import { ClassRounded } from "@material-ui/icons";
import classes from "./Value_Box1.module.css"


function Value_Box2 ({title, caption, imageURL}) {
    return (
        <div className={classes.container2}>
        
                <div className={`${classes.textbox} ${classes.right_margin}`}>
                    <h1 className={classes.title}>{title}</h1>
                    <p className={classes.caption}>
                       {caption}
                    </p>
                </div>  

                <div className={`${classes.Color_Card}`}>
                    <div className={classes.image}>
                        <img className={classes.img} src={`${imageURL}`} />
                    </div>
                </div> 
            
        </div>
    )
}


export default Value_Box2;