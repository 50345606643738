import classes from "./Row2.module.css"


function Row2 () {
    return (
        <div className={classes.container}>
            <div className={classes.row2}>
                <div className={classes.textbox}>
                <h1 className={classes.title}>Harnessing <span className={classes.bold}> Collective  Intelligence</span> through <span className={classes.bold}> AI </span> </h1>
                <p className={classes.caption}>Empowering enterprises to make accurate decisions and achieve superior performance through the wisdom of the crowd</p>
                </div>
                <div>
                    <img className={classes.image} src="/images/Bditto_Labs/Row2.png"  />
                </div>     
            </div>
        </div>
    )
}


export default Row2;