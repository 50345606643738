import { useState } from "react";
import SmallBlogCard from "../Small_Blog_Card/Small_Blog_Card";
import classes from "./Blog_Card_Container.module.css";

function Blog_Card_Container (props) {
    var arr = props.data
    return (
      
      <div className={classes.Blog_Card_Container}>
          {arr.map(blog => (
          <SmallBlogCard 
          title={blog?.title}
          caption={''}
          date={blog?.pubDate} 
          media={blog?.thumbnail}
          tags={blog?.categories}
          id={blog?.id}
          />
          ))

        }
         
      </div>
      
    )
}


export default Blog_Card_Container;