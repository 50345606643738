import classes from "./Title_Button_Block.module.css"
import VioletButton from "../../Buttons/Violet_Button/Violet_Button";
import { useHistory } from "react-router-dom";



function Title_Button_Block3 () {
    
    const history = useHistory();
    return (
       
        <div className={classes.heading3}>

            <h2 className={classes.heading1_h}>< span class={classes.bold}>Collaborate, connect </span>& < span class={classes.bold}>converse </span>with your team</h2>
            <div className={classes.heading1_button} >
                <a href="https://dashboard.bditto.com/register">
                    <VioletButton className={classes.heading1_button}>Explore Demo    <span className={classes.heading1_button_arrow}>&#8594;</span></VioletButton>
                </a>
            </div>

        </div> 
    )
}


export default Title_Button_Block3;