import React, { Component, createRef } from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core/styles";
import styles from "./InterestSidebar.module.css";
import { Avatar, IconButton } from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";
import { FormattedMessage } from "react-intl";
import StatusCard from "../../Extras/StatusCard/StatusCard";
import SmallStatusCard from "../../Extras/SmallCard/SmallStatusCard";
const useStyles = (theme) => ({
 
});

class SuggestionsSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
    };
    this.searchInputRef = createRef();
  }

 
  render() {
    const { classes } = this.props;
    const avatar = {marginLeft:'0.5rem' }

    const isMobile = window.screen.width < 600;
    return (
      <div className={styles.addContainer}>
        <div className={styles.addStatusContainer} >  
          <span className={styles.thoghts} style={{margin:'0rem 0'}}>
            <h3 className={styles.thoughtsHead} >Suggestions</h3>
          </span>         
        </div>
        <span className={styles.feednames} style={{marginTop:'-2rem'}}> 
          <img src="/dashboard/user6.svg" style={avatar}/> &nbsp;&nbsp;  
          <span style={{color:'var(--heading-color2)'}}>@adriana_black</span>
          <img src="/dashboard/pinkPlus.svg" style={{marginLeft:'auto'}} />
        </span>
        <span className={styles.feednames}>
          <img src="/dashboard/user4.svg" style={avatar}/> &nbsp;&nbsp;  
          <span style={{color:'var(--heading-color2)'}}>@caroline</span>
          <img src="/dashboard/pinkPlus.svg" style={{marginLeft:'auto'}} />
        </span>
        <span className={styles.feednames}>
          <img src="/dashboard/user3.svg" style={avatar}/> &nbsp;&nbsp;  
          <span style={{color:'var(--heading-color2)'}}>@zoe</span>
          <img src="/dashboard/pinkPlus.svg" style={{marginLeft:'auto'}} />
        </span>
      </div>
    );
  }
}


export default(withStyles(useStyles)(withRouter(SuggestionsSidebar)));
