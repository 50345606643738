import axios from 'axios';
import { useEffect, useState } from 'react';
import BlogContext from '../Context';

const BlogProvider = (props) => {
    const [loaded, setloaded] = useState(false)
    const [blogs, setblogs] = useState([])
    const [image, setimage] = useState()
    useEffect(() => {
        
        const medium = axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@bditto')
        
        Promise.all([medium]).then(res =>(setloaded(true),setblogs(res[0].data.items),setimage(res[0].data.feed["image"])))
        
    }, [])
    blogs.forEach((value,i) => {value["id"] = i+1})
    function chunk(array, size) {
        const chunked_arr = [];
        let index = 0;
        while (index < array.length) {
          chunked_arr.push(array.slice(index, size + index));
          index += size;
        }
        return chunked_arr;
      }
    const arr1 = chunk(blogs,4)
    return (
        <BlogContext.Provider
          value={{
            blogs: arr1,
            medium:blogs,
            loaded: loaded,
            image:image,
          }}
        >
          {props.children}
        </BlogContext.Provider>
      );

}

export default BlogProvider