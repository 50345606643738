import React from 'react'

const ThoughtIcon = ({color}) => {
    return (
        
        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="13">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.333374 0H13.3327V12.9993H0.333374V0Z" fill={color}/>
</mask>
<g mask="url(#mask0)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.20334 7.87835L8.64134 11.8337C8.748 12.007 8.91467 12.005 8.98201 11.9957C9.04934 11.9864 9.21134 11.945 9.27 11.7484L12.3187 1.45169C12.372 1.26969 12.274 1.14569 12.23 1.10169C12.1873 1.05769 12.0653 0.963685 11.8887 1.01369L1.58467 4.03102C1.38934 4.08835 1.34667 4.25235 1.33734 4.31968C1.328 4.38835 1.32534 4.55835 1.498 4.66702L5.49867 7.16902L9.03334 3.59702C9.22734 3.40102 9.544 3.39902 9.74067 3.59302C9.93734 3.78702 9.93867 4.10435 9.74467 4.30035L6.20334 7.87835ZM8.93 12.9997C8.466 12.9997 8.04067 12.7637 7.79 12.3584L5.20534 8.16435L0.968004 5.51435C0.511337 5.22835 0.272671 4.71902 0.346671 4.18369C0.420004 3.64835 0.787337 3.22302 1.30334 3.07168L11.6073 0.0543517C12.0813 -0.084315 12.5893 0.0470183 12.9387 0.395018C13.288 0.746352 13.418 1.25969 13.2767 1.73569L10.228 12.0317C10.0753 12.5497 9.64867 12.9157 9.11467 12.987C9.052 12.995 8.99134 12.9997 8.93 12.9997Z" fill={color}/>
</g>
</svg>

        
    )
}

export default ThoughtIcon
