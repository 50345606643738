import ImageTitleRow from "./Image_Title_Row/Image_Title_Row";
import TitleButtonBlock3 from "../Title_Button_Block/Title_Button_Block3";
import classes from "./Image_Grid.module.css"
import { useMediaQuery } from "react-responsive";



function Image_Grid () {
    const isDesktop = useMediaQuery({ query: "(min-device-width: 768px)" });
    const image1 = isDesktop ? "images/Image_Grid/image_grid1.png" : "images/Image_Grid/image_grid1_small.png";
    const image2 = isDesktop ? "images/Image_Grid/image_grid2.png" : "images/Image_Grid/image_grid2_small.png";
    const image3 = isDesktop ? "images/Image_Grid/image_grid3.png" : "images/Image_Grid/image_grid3_small.png";
    const image4 = isDesktop ? "images/Image_Grid/image_grid4.png" : "images/Image_Grid/image_grid4_small.png";
    return (
        <div>
            <ImageTitleRow 
                image1={image1}
                image2={image2}
                title1="Finance"
                title2="HR Department"
                corner="right"
            />

            <TitleButtonBlock3 />
            

            <ImageTitleRow 
                image1={image3}
                image2={image4}
                title1="Founder"
                title2="Marketing Head"
                corner="left"
                padding="right"
            />
     </div>
    )
}


export default Image_Grid;