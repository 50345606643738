import classes from "./Hashtag.module.css";
import { useHistory} from 'react-router-dom';

function Hashtag (props) {
  
  const history = useHistory()
    return (
    <div className={classes.Hashtag_container}>
      <button className={classes.Hashtag}>
          <p className={classes.button_text} onClick={()=> history.push(`/hashtag/${props.hashtag}`)}>{props.children}</p>
      </button>
    </div>
    );
}


export default Hashtag;