import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from "react-responsive";
import Nav from './Nav';

import styles from "./NavMore.module.css";

const NavMore = ({popover,setPopover}) => {
    const location = useLocation().pathname;
    const isDesktop = useMediaQuery({ minWidth: 900 });

    const clickHandler = () => {
      setPopover(!popover)
    }

    return (
        <div className={styles.popover}>
          <Nav hideTabs={ isDesktop ? false : true} />
          <div className={styles.btns}>
            <div className={styles.btn} onClick={clickHandler}>
              <Link to="/" className={location === "/" ? styles.selected : styles.link} >
                <span>Home</span>
              </Link>
            </div>
            <div className={styles.btn} onClick={clickHandler}>
              <Link to="/lab" className={location === "/lab" ? styles.selected : styles.link} >
                <span>Bditto Labs</span>
              </Link>
            </div>
            <div className={styles.btn} onClick={clickHandler}>
              <Link to="/blog" className={location === "/blog" ? styles.selected : styles.link} >
                <span>Blog</span>
              </Link>
            </div>
            <div className={styles.btn} onClick={clickHandler}>
              <Link to="/aboutus" className={location === "/aboutus" ? styles.selected : styles.link} >
                <span>About Us</span>
              </Link>
            </div>
            <div className={styles.btn} onClick={clickHandler}>
              <Link to="/business" className={location === "/business" ? styles.selected : styles.link} >
                <span>Business</span>
              </Link>
            </div>
            <div className={styles.btn} onClick={clickHandler}>
              <Link to="/education" className={location === "/education" ? styles.selected : styles.link} >
                <span>For Education</span>
              </Link>
            </div>
          </div>
        </div>
    )
}

export default NavMore
