import classes from "./Row4.module.css"


function Row4 () {
    return (
        <div className={classes.container}>
            <div className={classes.row4}>
                <div className={classes.textbox}>
                <h1 className={classes.title}> Bring   innovation to  <span className={classes.bold}> new ways of   collaboration</span> </h1>
                <p className={classes.caption}>We want to reinvent how people collaborate and make decisions faster</p>
                </div>
                <div>
                    <img className={classes.image} src="/images/Bditto_Labs/Row4.png"  />
                </div>     
            </div>
        </div>
    )
}


export default Row4;