import { useState } from "react";
import Hashtag from "./Hashtag";
import classes from "./Hashtag_Container.module.css";

function Hashtag_Container (props) {
    
    // const [hashTags, handlehashtags] = useState(["IncreaseProductivity", "BuisnessTips", "BdittoTour", "WhyBditto?"]);
    console.log(props.hashtags)
    return (
      <div className={classes.Hashtag_Container}>
           { props.hashtags.map(hashTag => ( 
                <div className={classes.shiftright} key={hashTag.id}>
                    <Hashtag hashtag={hashTag.id} >{hashTag.name}</Hashtag>
                </div>
                )
            )}
      </div>
    )
}


export default Hashtag_Container;