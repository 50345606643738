import React from 'react'
import styles from "./Chat.module.css"

import { IconButton, Slide, Tooltip } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
const Chat = () => {
    return (
        <div className={styles.container}>
          <div className={styles.title} >
            <img src="https://raw.githubusercontent.com/devias-io/material-kit-react/main/public/static/images/avatars/avatar_8.png" width="32" style={{borderRadius:'50%'}} />
            <h1 className={styles.contentHead}>
              Exploring new work from home policy
            </h1>
            <div className={styles.rightTitle} >
              <IconButton >
                <img src="/dashboard/search.svg"  />
              </IconButton>
              <IconButton  size="small">
                <img src="/dashboard/options.svg" style={{transform :'rotate(90deg)', marginLeft:'0.6rem'}} />
              </IconButton>
            </div>
          </div>

          <div className={styles.mainChatBox} >
            <div className={styles.chat}>
              <div className={`${styles.leftChatContent} `}>
                <span className={styles.chatUser}> <img src="/dashboard/user1.svg" />
                  <h1 className={styles.content}>@nikole_wills</h1>
                </span>
                <div className={styles.messageCont}>
                  Guys, we have a new work from home policy released just this week. What do you think about it.
                </div>
                <div className={styles.time}>16:49</div>
              </div>

              <div className={`${styles.myChatContent1} `}>
                <span className={styles.chatUser}>
                  <Avatar style={{width:'1.7em' , height:'1.7em', background: "#D9DEF4", color: "#333"}}>G</Avatar>
                  <h1 className={styles.content}>@Grace</h1>
                </span>
                <div className={styles.messageContRight}>
                  It looks great and quite flexible

                </div>
                <div className={styles.timer}>16:49</div>
              </div>

              <div className={`${styles.leftChatContent} `}>
                <span className={styles.chatUser}>
                  <img src="/dashboard/user4.svg" width="32" style={{borderRadius:'50%'}} />
                  <h1 className={styles.content}>@zoe</h1>
                </span>
                <div className={styles.messageCont}>
                  Anyone checked how the insurance works in case of any uncertain events
                </div>
                <div className={styles.time}>16:49</div>
              </div>

              <div className={`${styles.leftChatContent} `}>
                <span className={styles.chatUser}>
                  <img src="/dashboard/user1.svg" width="32" style={{borderRadius:'50%'}} />

                  <h1 className={styles.content}>@larry_bowman</h1>
                </span>
                <div className={styles.messageCont}>
                  I looked into the insurance part and the risk is fully covered in case of any unforeseen events as listed in the policy
                </div>
                <div className={styles.time}>16:49</div>
              </div>

              <div className={`${styles.leftChatContent} `}>
                <span className={styles.chatUser}> <img src="/dashboard/user1.svg" />
                  <h1 className={styles.content}>@nikole_wills</h1>
                </span>
                <div className={styles.messageCont}>
                  Is this still covered if I am working away from my country where my work contract is based?
                </div>
                <div className={styles.time}>16:49</div>
              </div>
              <div className={`${styles.repeatleftChatContent} `}>
                <div className={styles.messageCont}>
                  I think it needs prior approval from the line manager, just to ensure that the insurance is covered.
                </div>
                <div className={styles.time}>17:49</div>
              </div>

            </div>
          </div>
          <div className={styles.inputBox}>
            <img src="/dashboard/smile.svg" />
            <img src="/dashboard/attachment.svg" style={{marginLeft:'0.7rem'}}/>
            <img src="/dashboard/mic.svg" style={{marginLeft:'0.7rem'}}/>
            <input placeholder="Write a message" className={styles.inputMsg}/>
            <button className={styles.sendBtn} >Send</button>
          </div>
        </div>
    )
}

export default Chat
