import React, { Component, createRef } from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { withStyles } from "@material-ui/core/styles";
import styles from "./Sidebar.module.css";
import { Avatar, IconButton } from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";
import { FormattedMessage } from "react-intl";
import StatusCard from "../../Extras/StatusCard/StatusCard";
import SmallStatusCard from "../../Extras/SmallCard/SmallStatusCard";
const useStyles = (theme) => ({
  addStatus: {
    border: "2px solid rgba(0, 198, 255, 0.85)",
    borderRadius: "98px",
    opacity: "1",
    fontSize: "14px",
    paddingRight:'auto',
    marginTop:'1.2rem',
    color: "var(--heading-color1)",
    textTransform: "none",
    height: "46px",
    outline: "none !important",
    marginBottom: "1.5rem",
    width: "92%",
    fontStyle: "normal",
    lineHeight: "19.9px",
    fontWeight: "400",
  },
});

class ConnectionSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainClass: true,
      isStatusLoading: false,
      allMyStatus: [],
      popAddStatus: false,
      statusContent: "",
      popEditStatus: false,
      editStatusContent: null,
      alert: false,
      searchTerm: "",
    };
    this.searchInputRef = createRef();
  }

 
  render() {
    const { classes } = this.props;

    const avatar = {marginLeft:'0.5rem',background: 'linear-gradient(92.12deg, #FC466B 0%, #3F5EFB 100%)', padding:'0.1rem', borderRadius:'50%' }

    const { isStatusLoading, allMyStatus } = this.state;
    const isMobile = window.screen.width < 600;
    return (
      <>
        <div className={styles.addContainer}>
            
          <div id="Add_Status" className={styles.addStatusContainer}>
          <span className={styles.thoughts} style={{marginTop:'-1rem'}} > 
          {
            this.props.dash == "chat"?
            <h3 className={styles.thoughtsHead}>Thoughts&nbsp;<span style={{color:'var(--secondary)'}}>Private Chat</span></h3>
            :<> 
            <img src="/dashboard/users.svg" style={{marginLeft:'0.5rem'}} />
          <h3 className={styles.thoughtsHead}>My Connections</h3>
           </>
          }
         
          </span>     
        
            <div className={styles.searchBox}>
              <img src="/icons/search.svg" />
              {this.props.dash == "chat" ?
              <input
              type="text"
              className={styles.searchField}
              disabled
              placeholder="Search thoughts"
            />
              :
               <input
                type="text"
                className={styles.searchField}
                disabled
                placeholder="Search your connections"
              />}
            </div>
            
          
          </div>
          {this.props.dash == "chat" ?
          <> 
          <div  className={styles.statusList}>
            <SmallStatusCard border_color="chat" width="90" item={"Exploring new work from home policy"} />
            <SmallStatusCard border_color="red" width="90" item={"Do we have to wear masks in the office"} />
            <SmallStatusCard border_color="red" width="90" item={"Any good restaurant recommendations for business Lunch in London"} />
        
            </div>
           </>
           :
          <>
            <Button className={classes.addStatus}>    
              <Avatar style={{width:'1.5rem', height:'1.5rem'}}>M</Avatar>     &nbsp;
                <FormattedMessage id="addThoughts" defaultMessage={`All Connections`} />
            </Button> 
            <div  className={styles.statusList}>
              <span className={styles.feednames}> <img src="/dashboard/user5.svg" style={avatar}/> &nbsp;&nbsp;  
              <span style={{color:'var(--heading-color2)', marginTop:'0.2rem', fontSize: ".9rem"}}>@charles_ortega
              </span>
              </span>

              <span className={styles.feednames}> <img src="/dashboard/user1.svg" style={avatar}/> &nbsp;&nbsp;  
              <span style={{color:'var(--heading-color2)', marginTop:'0.2rem', fontSize: ".9rem"}}>@nikole_wills
              </span>
              </span>
              <span className={styles.feednames}> <img src="/dashboard/user2.svg" style={avatar}/> &nbsp;&nbsp;  
              <span style={{color:'var(--heading-color2)', marginTop:'0.2rem', fontSize: ".9rem"}}>@larry_bowman
              </span>
              </span>
            </div> 
          </>
          }


        </div>
      </>
    );
  }
}


export default(withStyles(useStyles)(withRouter(ConnectionSidebar)));
