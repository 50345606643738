import classes from "./Image_Title_Row.module.css";
import ImageCard from "../Image_Card/Image_Card";
import TitleCard from "../Title_Card/Title_Card";



function Image_Title_Row ({ image1, title1, image2, title2, corner, padding}) {
   
    const padding_class = padding == 'right' ? classes.padding : null;
    return (   
        <div className={classes.image_title_row}>
            <div className={classes.image_row}>
                <ImageCard 
                    imageURL = {image1}
                    corner={corner} 
                />
                <ImageCard 
                    imageURL = {image2}
                    corner={corner} 
                />
            </div> 
            <div className={`${classes.title_row} ${padding_class}`}>
                <TitleCard 
                    text={title1}
                    corner={corner}
                />
                 <TitleCard 
                    text={title2}
                    corner={corner}
                />
            </div> 
        </div> 
    )
}


export default Image_Title_Row;