import { useEffect, useState } from "react";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import FeatureCard from "./Feature_Card/Feature_Card";
import classes from "./Feature_Scroll_Card.module.css";

function Feature_Scroll_Card () {
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const istab = useMediaQuery({maxWidth:  1188 });
    const Feature_Card_Data = [
        {
            title: 'Thoughts hub',
            content: 'Users simply post their thoughts, questions or problems and via artificial intelligence get seamlessly linked with other coworkers to instantly collaborate and communicate.',
            border: 'red',
        },
        {
            title: 'Explore',
            content: 'Pulse of your organization. With our “popular, trending & buzzing” feature, get real-time updates and insights on employee engagement, ideas, discussions, and more.',
            border: 'blue',
        },
        {
            title: 'Workspaces',
            content: 'Create private and public workspaces to have focused discussions within a particular topic or related to a particular department of a company.',
            border: 'green',
        },
        {
            title: 'Filter',
            content: 'With a variety of different filters, you can either post or search your topics in different departments, locations, hierarchy levels and decide who can see your thoughts and interact.',
            border: 'yellow',
        },
        {
            title: 'Profile',
            content: 'Set up your own personal company profile and interests. Never miss out on interesting thoughts, get inspired and make yourself visible.',
            border: 'red',
        },
        {
            title: 'History and Bookmarks',
            content: 'Being lost and clueless as a “Newbie” in the office and not knowing who to ask, is a thing of the past. All the corporate`s knowledge now has one central spot, and you can even bookmark and manage content.',
            border: 'blue',
        },
        {
            title: 'Message',
            content: 'Our instant messenger allows users to communicate with other team members either in open group channels or in private. It also allows them to share and manage files easily and quickly.',
            border: 'green',
        },
    ]

    const [slideIndex, setSlideIndex] = useState(0);
    const settings = {
        dots: true,
        dotsClass: classes.dots,
        arrows: isDesktop,
        infinite: true,
        speed: 500,
        slidesToShow: isDesktop ?  1.63 : 1,
        slidesToScroll: isDesktop ?  1.54 : 1,
        centerPadding: isDesktop ? 20: -50,
        centerMode: true,
        beforeChange: (current, next) => setSlideIndex(next),
        appendDots: dots => {
            return (
              <div>
                <ul className={classes.dots}>
                  {dots.map((item, index) => {
                    return (
                      <li key={index}>{item.props.children}</li>
                    );
                  })}
                </ul>
              </div>
            )
        },
        customPaging: index => {
            return (
              <button className={index === slideIndex ? classes.active : classes.dotsBtn}>
                {index + 1}
              </button>
            )
        }
    };

    return (
        <Slider {...settings}>
            {Feature_Card_Data.map((data, index) => (
                    <FeatureCard  
                        border_color = {data.border}
                        title = {data.title}
                        content = {data.content}
                        rotate={ isDesktop ? index === slideIndex  ? 'left' : index === (slideIndex + 2)%Feature_Card_Data.length ? 'right' : null : null} 
                    />))
            } 
        </Slider>
    )
}


export default Feature_Scroll_Card;