import classes from "./Row1.module.css"


function Row1 () {
    return (
        <div className={classes.container}>
            <div className={classes.row}>
                <div className={classes.textbox}>
                <h1 className={classes.title}>Our <span className={classes.gradient_text}> Mission</span> </h1>
                <p className={classes.caption}>To be the platform of choice for revolutionizing the way humans work, engage and thrive - and fuelling a culture of people working together. 
                        <br />
                        <br />
                        Enabling a new way of work where teams, organizations and businesses are able to thrive and do amazing things.
                </p>
                </div>
                <div>
                    <img className={classes.image} src="/images/About_Us/Row1.svg"  />
                </div>     
            </div>
        </div>
    )
}


export default Row1;