import classes from "./Transparent_Blue_Button.module.css";

function Transparent_Blue_Button (props) {
    return (
      <button className={classes.Transparent_Blue_Button}>
          <p className={classes.button_text}>{props.children}</p>
        </button>
    );
}


export default Transparent_Blue_Button;