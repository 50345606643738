import Nav from "../Nav/Nav";
import Row1 from "./Row1/Row1";
import Row2 from "./Row2/Row2";
import Row3 from "./Row3/Row3";
import Row4 from "./Row4/Row4";
import Row5 from "./Row5/Row5";
import { useMediaQuery } from "react-responsive";
import Footer from "../Extras/Footer/Footer";



function Bditto_Labs () {

    const isDesktop = useMediaQuery({ query: "(min-device-width: 768px)" });

    return (
        <Nav hideTabs={ isDesktop ? false : true}>
            <Row1 />
            <Row2 />
            <Row3 />
            <Row4 />
            <Row5 />
        </Nav>
    )
}


export default Bditto_Labs ;